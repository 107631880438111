/*
 * File: app.module.ts
 * Project: boxpart-console
 * File Created: Thursday, 14th October 2021 10:07:12 am
 * Author: Jorge Herrera (jfelix@vonbraunlabs.com.br)
 * -----
 * Copyright 2021 - Von Braun Labs.
 * -----
 * HISTORY:
 * Date      	By	Comments
 * ----------	---	---------------------------------------------------------
 * 2021-10-14	JH	Main application module. Define imports and libraries.
 * 2021-10-20	TC	File path adjustment (components folder)
 * 2021-11-10	JH	Added I18N support.
 */

import { LOCALE_ID, NgModule } from '@angular/core';
import { registerLocaleData } from '@angular/common';
import { HttpClient, HttpClientModule } from '@angular/common/http';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import localeES from '@angular/common/locales/es';
import localePT from '@angular/common/locales/pt';
import { TranslateLoader, TranslateModule } from '@ngx-translate/core';
import { TranslateHttpLoader } from '@ngx-translate/http-loader';

import { MatTableModule } from '@angular/material/table';
import { MatStepperModule } from '@angular/material/stepper';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatInputModule } from '@angular/material/input';
import { MatButtonModule } from '@angular/material/button';
import { MatRadioModule } from '@angular/material/radio';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { MatChipsModule } from '@angular/material/chips';
import { MatIconModule } from '@angular/material/icon';
import { MatPaginatorModule } from '@angular/material/paginator';

import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { Ng2OrderModule } from 'ng2-order-pipe';
import { Ng2SearchPipeModule } from 'ng2-search-filter';
import { NgxPaginationModule } from 'ngx-pagination';
import { DataTablesModule } from 'angular-datatables';
import { QRCodeModule } from 'angularx-qrcode';
import { NgxBarcodeModule } from 'ngx-barcode';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { FilesComponent } from './components/files/files.component';
import { HomeComponent } from './components/home/home.component';
import { LoginComponent } from './components/login/login.component';
import { PrinterComponent } from './components/printer/printer.component';
import { SequencesComponent } from './components/sequences/sequences.component';
import { ListLogsComponent } from './components/list-logs/list-logs.component';
import { AppToastsComponent } from './visuals/apptoast/apptoast.component';
import { ExpeditionComponent } from './components/expedition/expedition.component';
import { ExpeditionPrintComponent } from './components/expedition-print/expedition-print.component';

import { AppToastService } from './services/apptoast.service';
import { LoginService } from './services/login.service';
import { PrinterService } from './services/printer.service';
import { MetadataService } from './services/metadata.service';
import { LogsService } from './services/logs.service';
import { DashboardComponent } from './components/dashboard/dashboard.component';
import { LogisticPanelComponent } from './components/logistic-panel/logistic-panel.component';
import { NgChartsModule } from 'ng2-charts';
import { ExpeditionListComponent } from './components/expedition-list/expedition-list.component';
import { GenerateTagsComponent } from './components/generate-tags/generate-tags.component';
import { SequencingDashboardComponent } from './components/sequencing-dashboard/sequencing-dashboard.component';
import { DeclarationPrintComponent } from './components/declaration-print/declaration-print.component';
import { ConfigurationComponent } from './components/configuration/configuration.component';
import { GenerateReportsComponent } from './components/generate-reports/generate-reports.component';
import { MarketComponent } from './components/market/market.component';
import { FlatFilesLogsComponent } from './components/flat-files-logs/flat-files-logs.component';

registerLocaleData(localePT);
registerLocaleData(localeES);

// AoT requires an exported function for factories
export function HttpLoaderFactory(http: HttpClient): TranslateHttpLoader {
  return new TranslateHttpLoader(http);
}

@NgModule({
  declarations: [
    AppComponent,
    AppToastsComponent,
    LoginComponent,
    HomeComponent,
    FilesComponent,
    ListLogsComponent,
    PrinterComponent,
    SequencesComponent,
    DashboardComponent,
    ExpeditionComponent,
    ExpeditionPrintComponent,
    LogisticPanelComponent,
    ExpeditionListComponent,
    GenerateTagsComponent,
    SequencingDashboardComponent,
    DeclarationPrintComponent,
    ConfigurationComponent,
    GenerateReportsComponent,
    MarketComponent,
    FlatFilesLogsComponent,
  ],
  imports: [
    BrowserModule,
    AppRoutingModule,
    FormsModule,
    ReactiveFormsModule,
    HttpClientModule,
    NgbModule,
    Ng2SearchPipeModule,
    Ng2OrderModule,
    NgxPaginationModule,
    BrowserAnimationsModule,
    MatTableModule,
    MatStepperModule,
    DataTablesModule,
    MatFormFieldModule,
    MatButtonModule,
    MatInputModule,
    MatRadioModule,
    MatDatepickerModule,
    MatCheckboxModule,
    MatChipsModule,
    MatIconModule,
    MatPaginatorModule,
    QRCodeModule,
    NgxBarcodeModule,
    TranslateModule.forRoot({
      loader: {
        provide: TranslateLoader,
        useFactory: HttpLoaderFactory,
        deps: [HttpClient],
      },
      defaultLanguage: 'en',
    }),
    NgChartsModule,
  ],
  providers: [
    LoginService,
    AppToastService,
    PrinterService,
    MetadataService,
    LogsService,
    // Change this 'navigator.language' setting to force using another locale.
    { provide: LOCALE_ID, useValue: navigator.language },
  ],
  bootstrap: [AppComponent],
})
export class AppModule {}
