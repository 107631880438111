/* eslint-disable @typescript-eslint/no-explicit-any */
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';

import { environment } from '@env';
import { LoginService } from './login.service';

import { ReportDto } from '@atypes/report.types';

const API = environment.applicationApiUrl;

@Injectable({
  providedIn: 'root'
})
export class ReportService {

  constructor(private http: HttpClient, private login: LoginService) { }

  // Load the header authorization for requests
  // Use loadAuthData from login service and HttpHeaders
  prepareRequest() {
    const auth = this.login.loadAuthData();
    const headers = new HttpHeaders().set(
      'Authorization',
      `bearer ${auth.token}`
    );
    return headers;
  }

  generateReport(reportDto: ReportDto) {
    const options = {
      headers: this.prepareRequest(),
      responseType: 'blob' as 'json'
    };

    return this.http.post<any>(`${API}/report/generate`, reportDto, options).toPromise();
  }
}
