import { Router } from '@angular/router';
import { SequencesService } from 'src/app/services/sequences.service';
import { Component, OnInit, OnDestroy, ViewChild, Inject, LOCALE_ID } from '@angular/core';
import {
  NgbDate,
  NgbCalendar,
  NgbDateParserFormatter,
  NgbModal,
  ModalDismissReasons,
} from '@ng-bootstrap/ng-bootstrap';
import { Subject } from 'rxjs';
import { MetadataService } from '@services/metadata.service';
import { AppToastService } from '../../services/apptoast.service';
import { TranslateService } from '@ngx-translate/core';
import { DataTableDirective } from 'angular-datatables';
import { expeditionReprintDto } from '@atypes/expedition.types';
import { NgbDateCustomParserFormatter } from 'src/app/services/date-parse-formatter.service';
import { ErrorMessageService } from '@services/error-message.service';

export interface IExpeditionDto {
  dateTime: string;
  id: number;
  status: number;
  transportType: string;
  vehicleType: string;
}

@Component({
  selector: 'app-expedition-list',
  templateUrl: './expedition-list.component.html',
  styleUrls: ['./expedition-list.component.css'],
})
export class ExpeditionListComponent implements OnInit, OnDestroy {
  loading = false;

  @ViewChild(DataTableDirective, { static: false })
  dtElement: DataTableDirective | undefined;

  dtOptions: DataTables.Settings = {};
  expeditions: IExpeditionDto[] = [];

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  dtTrigger: Subject<any> = new Subject<any>();

  hoveredDate: NgbDate | null = null;

  fromDate: NgbDate | null;
  toDate: NgbDate | null;

  maxDate: NgbDate | null;

  closeResult = '';
  modalData: IExpeditionDto = {} as IExpeditionDto;
  currentlyPopIdList: expeditionReprintDto[] = [];

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  errorsMetadata: any;

  constructor(
    @Inject(LOCALE_ID) public locale: string,
    private calendar: NgbCalendar,
    public formatter: NgbDateParserFormatter,
    private translate: TranslateService,
    private metadataService: MetadataService,
    private toast: AppToastService,
    private sequenceService: SequencesService,
    private router: Router,
    private modalService: NgbModal,
    private errorMessageService: ErrorMessageService
  ) {
    this.errorsMetadata = this.metadataService.getErrors();
    this.fromDate = calendar.getPrev(calendar.getToday(), 'd', 15);
    this.toDate = calendar.getNext(calendar.getToday(), 'd', 15);
    this.maxDate = calendar.getNext(this.fromDate, 'd', 30);
  }

  async ngOnInit() {
    this.dtOptions = {
      pagingType: 'full_numbers',
      pageLength: 10,
      order: [],
      columnDefs: [{ type: 'date', targets: 0 }],
      language: this.translate.instant('logs'),
    };

    let fromDate: Date = new Date();
    let toDate: Date = new Date();

    if (this.fromDate) fromDate = new Date(this.fromDate.year, this.fromDate.month - 1, this.fromDate.day);
    if (this.toDate) toDate = new Date(this.toDate.year, this.toDate.month - 1, this.toDate.day);

    this.expeditions = await this.sequenceService
      .getExpeditons(fromDate.toISOString(), toDate.toISOString())
      .toPromise();
    this.expeditions.map(expedition => (expedition.dateTime += 'Z'));

    this.dtTrigger.next();
  }

  ngOnDestroy(): void {
    // Do not forget to unsubscribe the event
    this.dtTrigger.unsubscribe();
  }

  async onDateSelection(date: NgbDate) {
    if (!this.fromDate && !this.toDate) {
      this.fromDate = date;
    } else if (this.fromDate && !this.toDate && date && date.after(this.fromDate)) {
      this.toDate = date;
      await this.refreshTable();
    } else {
      this.toDate = null;
      this.fromDate = date;
    }
    this.maxDate = this.calendar.getNext(this.fromDate, 'd', 30);

    NgbDateCustomParserFormatter.formatDate(date);
  }

  isHovered(date: NgbDate) {
    return (
      this.fromDate && !this.toDate && this.hoveredDate && date.after(this.fromDate) && date.before(this.hoveredDate)
    );
  }

  isInside(date: NgbDate) {
    return this.toDate && date.after(this.fromDate) && date.before(this.toDate);
  }

  isRange(date: NgbDate) {
    return (
      date.equals(this.fromDate) ||
      (this.toDate && date.equals(this.toDate)) ||
      this.isInside(date) ||
      this.isHovered(date)
    );
  }

  validateInput(currentValue: NgbDate | null, input: string): NgbDate | null {
    const parsed = this.formatter.parse(input);
    return parsed && this.calendar.isValid(NgbDate.from(parsed)) ? NgbDate.from(parsed) : currentValue;
  }

  async refreshTable() {
    this.dtElement?.dtInstance.then(async (dtInstance: DataTables.Api) => {
      // Destroy the table first
      dtInstance.destroy();

      let fromDate: Date = new Date();
      let toDate: Date = new Date();

      if (this.fromDate) fromDate = new Date(this.fromDate.year, this.fromDate.month - 1, this.fromDate.day);
      if (this.toDate) toDate = new Date(this.toDate.year, this.toDate.month - 1, this.toDate.day);
      this.expeditions = await this.sequenceService
        .getExpeditons(fromDate.toISOString(), toDate.toISOString())
        .toPromise();
      this.expeditions.map(expedition => (expedition.dateTime += 'Z'));

      // Call the dtTrigger to rerender again
      this.dtTrigger.next();
    });
  }

  goToNewExpedition() {
    this.router.navigate(['/expedition/0']);
  }

  async openDetailModal(content, expedition: IExpeditionDto) {
    this.loading = true;
    this.currentlyPopIdList = [];

    this.modalData = expedition;
    const popIdList: expeditionReprintDto[] = [];

    try {
      const boxes = await this.sequenceService.getExpeditonReprintById(expedition.id).toPromise();

      boxes.map((box: expeditionReprintDto) => {
        if (!popIdList.some(item => item.popId === box.popId && item.productType === box.productType)) popIdList.push(box);
      });

      this.currentlyPopIdList = popIdList;
    } catch (error) {
      console.log(error);
    }
    this.loading = false;

    this.modalService
      .open(content, {
        ariaLabelledBy: 'Expedição',
        centered: true,
        size: 'lg',
      })
      .result.then(
        result => {
          this.closeResult = `Closed with: ${result}`;
        },
        reason => {
          this.closeResult = `Dismissed ${this.getDismissReason(reason)}`;
        }
      );
  }

  async goToEditExpeditionPage(expedition: IExpeditionDto) {
    this.router.navigate(['/expedition', JSON.stringify(expedition)]);
  }

  removeExpedition(expedition: IExpeditionDto) {
    this.sequenceService.removeExpeditionRequest(expedition.id).subscribe(
      async () => {
        this.modalService.dismissAll();
        this.toast.success(this.translate.instant('expedition.removedSuccess'));
      },
      response => {
        const message = response.error.message ? response.error : response.message;
        this.toast.error(this.errorMessageService.translate(message.code));
      }
    );
    this.refreshTable();
  }

  private getDismissReason(reason: unknown): string {
    if (reason === ModalDismissReasons.ESC) {
      return 'by pressing ESC';
    } else if (reason === ModalDismissReasons.BACKDROP_CLICK) {
      return 'by clicking on a backdrop';
    } else {
      return `with: ${reason}`;
    }
  }

  // Transform 2021-10-17T00:00:00 to 17/10/2021 (DD/MM/AAAA) -> Display for user
  transformDateFormatToTable(date: string) {
    return new Date(date).toLocaleString(navigator.language, {
      year: 'numeric',
      month: 'numeric',
      day: 'numeric',
      hour: '2-digit',
      minute: '2-digit',
    });
  }

  // Get all checked items (parts)
  goToPrint(expedition: IExpeditionDto): void {
    this.modalService.dismissAll();
    const expeditionForm = {
      formHeader: {
        collectTime: expedition.dateTime,
        transportType: expedition.transportType,
        vehicleType: expedition.vehicleType,
      },
      uniquePopidList: this.currentlyPopIdList,
    };

    this.router.navigate([
      '/',
      {
        outlets: {
          print: ['print', JSON.stringify(expeditionForm)],
        },
      },
    ]);
    setTimeout(() => {
      window.print();
      this.router.navigate([{ outlets: { print: null } }]);
    }, 1000);
  }
}
