import { ConfigurationService } from './../../services/configuration.service';
import { Component, OnInit } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'app-configuration',
  templateUrl: './configuration.component.html',
  styleUrls: ['./configuration.component.scss'],
})
export class ConfigurationComponent implements OnInit {
  constructor(public configurationService: ConfigurationService, private translate: TranslateService) {}

  async ngOnInit(): Promise<void> {
    await this.configurationService.loadConfiguration();
  }

  async updateConfiguration(): Promise<void> {
    this.configurationService.updateConfiguration();
  }
}
