/* eslint-disable @typescript-eslint/no-explicit-any */
import { Injectable } from '@angular/core';
import { MetadataService } from './metadata.service';

@Injectable({
  providedIn: 'root',
})
export class ErrorMessageService {
  public error: any;
  public language: any;

  constructor(private metadata: MetadataService) {}

  setLanguage(language: string) {
    this.language = language;
  }

  translate(message: any): string {
    if (message) {
      if (this.language === 'pt') {
        return this.metadata.getErrors()[message].messagePT;
      } else {
        return this.metadata.getErrors()[message].message;
      }
    } else {
      return '';
    }
  }
}
