<div class="container">
  <div>
    <h1>{{ 'configuration.systemPreferences' | translate }}</h1>
    <h3>{{ 'configuration.systemPreferencesDescription' | translate }}</h3>
  </div>

  <div class="form-check form-switch">
    <input
      class="form-check-input"
      type="checkbox"
      id="enableFicheReading"
      [(ngModel)]="configurationService.enableFicheReading" />
    <label class="form-check-label" for="enableFicheReading">
      {{ 'configuration.enableFicheReading' | translate }}
      <br />
      <span> {{ 'configuration.enableFicheReadingDescription' | translate }} </span>
    </label>
  </div>

  <div class="form-check form-switch">
    <input
      class="form-check-input"
      type="checkbox"
      id="enableFichePrint"
      [(ngModel)]="configurationService.enableFichePrint" />
    <label class="form-check-label" for="enableFichePrint">
      {{ 'configuration.enableFichePrint' | translate }}
      <br />
      <span> {{ 'configuration.enableFichePrintDescription' | translate }} </span>
    </label>
  </div>

  <button
    [disabled]="configurationService.loadingRequest || !configurationService.checksIfConfigurationsChanged()"
    type="button"
    class="btn btn-primary"
    (click)="updateConfiguration()">
    {{ !configurationService.loadingRequest ? ('configuration.save' | translate) : '' }}
    <div *ngIf="configurationService.loadingRequest" class="spinner-border" role="status">
      <span class="sr-only">Loading...</span>
    </div>
  </button>
</div>
