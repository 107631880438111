import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from '@env';
import { LoginService } from './login.service';

/** API URL defined in environment files. */
const API = environment.applicationApiUrl;

@Injectable({
  providedIn: 'root',
})
export class SynchronizeService {
  constructor(private http: HttpClient, private login: LoginService) {}

  private prepareRequest() {
    const auth = this.login.loadAuthData();
    const headers = new HttpHeaders().set('Authorization', `bearer ${auth.token}`);
    return headers;
  }

  synchronizeFlatFiles() {
    const headers = this.prepareRequest();
    return this.http.patch(`${API}/synchro/flatFiles`, null, { headers: headers });
  }
}
