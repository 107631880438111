<div *ngFor="let popIdGroup of popIdGroups; index as popIdGroupIndex" class="popIdGroup">
  <div class="title">
    <img src="../../../assets/images/logo-psca.png" width="100" alt="" />
    <h1>ROMANEIO SCANIA - Expedição</h1>
    <div style="width: 100px"></div>
  </div>
  <div class="title" style="margin-top: 0.3rem; border-bottom: 0px">
    <h2>Check list de transporte</h2>
    <h2 style="color: red; font-weight: 700">{{ this.formatedDate }}</h2>
  </div>

  <table class="table table-bordered table-sm">
    <col style="width: 150px" />
    <tbody>
      <tr>
        <th scope="row">Tipo de transporte:</th>
        <td colspan="3">{{ formHeader.transportType }}</td>
      </tr>
      <tr>
        <th scope="row">Horário de coleta:</th>
        <td colspan="3">{{ this.formatedTime }}</td>
      </tr>
      <tr>
        <th scope="row">Transportadora:</th>
        <td colspan="3"></td>
      </tr>
      <tr>
        <th scope="row">Nome do motorista:</th>
        <td></td>
        <th scope="row" style="width: 50px">RG:</th>
        <td></td>
      </tr>
      <tr>
        <th scope="row">Placa da carroceria:</th>
        <td colspan="3"></td>
      </tr>
      <tr>
        <th scope="row">Veículo tipo:</th>
        <td colspan="3">{{ formHeader.vehicleType }}</td>
      </tr>
    </tbody>
  </table>

  <table class="table table-bordered table-sm">
    <col style="width: 75px" />
    <thead>
      <tr style="background-color: #f2dcdb">
        <th scope="col">Nº Item</th>
        <th scope="col">POP ID</th>
        <th scope="col">Fornecimento</th>
        <th scope="col">Tipo</th>
        <th scope="col">Obs</th>
      </tr>
    </thead>
    <tbody>
      <tr *ngFor="let popId of popIdGroup; index as i">
        <th scope="row">
          {{ quantityPerPage * popIdGroupIndex + i + 1 }}
        </th>
        <td>{{ popId.popId }}</td>
        <td>{{ popId.supplier }}</td>
        <td>{{ popId.productType }}</td>
        <td>{{ popId.observation }}</td>
      </tr>
    </tbody>
  </table>

  <table
    class="table table-bordered table-sm align-middle"
    style="margin-top: 20px; font-size: 0.8rem; page-break-inside: avoid !important">
    <col style="width: 150px" />
    <tbody>
      <tr>
        <th scope="row" colspan="4">Notas fiscais:</th>
        <th scope="row" style="width: 210px; text-align: center">ASSINATURA DO MOTORISTA</th>
      </tr>
      <tr>
        <th scope="row" colspan="4" style="text-align: center">INFORMAÇÕES DO MOTORISTA</th>
        <td rowspan="2"></td>
      </tr>
      <tr style="background-color: #e2f0d9">
        <td style="width: 270px">Confirmação do motorista:</td>
        <td style="width: 300px">&nbsp;</td>
        <td style="width: 150px">Volume total:</td>
        <td style="width: 200px"></td>
      </tr>
      <tr>
        <td colspan="4">
          A quantidade está correta entre as NFs e o físico: &nbsp; (&nbsp;&nbsp;) SIM &nbsp;&nbsp; (&nbsp;&nbsp;) NÃO
        </td>
        <th scope="row" style="text-align: center">AUDITORIA DE EMBARQUE</th>
      </tr>
      <tr>
        <td colspan="4">
          As embalagens estão em boas condições para transporte: &nbsp; (&nbsp;&nbsp;) SIM &nbsp;&nbsp; (&nbsp;&nbsp;)
          NÃO
        </td>
        <td rowspan="2" class="align-middle">
          <p style="text-align: center; padding: 10px auto; margin: 0">
            <img src="../../../assets/images/caminhao_romaneio.png" alt="" width="150" />
          </p>
        </td>
      </tr>
      <tr>
        <td colspan="4" style="background-color: #e2f0d9">&nbsp;</td>
      </tr>
      <tr class="table-secondary">
        <td colspan="5">CARREGADOR POR:</td>
      </tr>
      <tr class="table-secondary">
        <td colspan="2" style="width: 50px">DATA:</td>
        <td colspan="3">HORÁRIO DE SAÍDA DO VEÍCULO:</td>
      </tr>
    </tbody>
  </table>
</div>
