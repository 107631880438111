/*
 * File: home.component.ts
 * Project: boxpart-console
 * File Created: Thursday, 14th October 2021 10:23:31 am
 * Author: Jorge Herrera (you@you.you)
 * -----
 * Copyright 2021 - VB Labs.
 * -----
 * HISTORY:
 * Date      	By	Comments
 * ----------	---	---------------------------------------------------------
 * 2021-10-14	JH  Renders the home page.
 * 2021-11-10	JH	Added I18N support.
 */

import { Component } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';

/** The home page component. */
@Component({
  selector: 'app-home',
  templateUrl: './home.component.html',
  styleUrls: ['./home.component.css'],
})
export class HomeComponent {
  /** TranslateService instance needed for HTML in-place translation. */
  constructor(private translate: TranslateService) {}
}
