import { AppToastService } from './../../services/apptoast.service';
import { TranslateService } from '@ngx-translate/core';
import { DashboardService } from './../../services/dashboard.service';
import { Component, OnInit, OnDestroy } from '@angular/core';
import { formatDate } from '@angular/common';
import { MetadataService } from '../../services/metadata.service';
import { expeditionObject } from '@atypes/expedition.types';
import { kittingDatalakeBox } from './../../common/types/dashboard.types';
import { ErrorMessageService } from '@services/error-message.service';

@Component({
  selector: 'app-dashboard',
  templateUrl: './dashboard.component.html',
  styleUrls: ['./dashboard.component.scss'],
})
export class DashboardComponent implements OnInit, OnDestroy {
  boxQuantityPerState: number[] = [];
  total = 0;
  boxes: kittingDatalakeBox[] = [];
  date = '';

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  boxMetadata: any;

  interval: NodeJS.Timeout;

  endedExpeditions: expeditionObject[] = [];
  activeExpeditions: expeditionObject[] = [];
  othersExpeditions: expeditionObject[] = [];

  constructor(
    private dashboardService: DashboardService,
    private metadataService: MetadataService,
    private translate: TranslateService,
    private toast: AppToastService,
    private errorMessageService: ErrorMessageService
  ) {
    this.boxMetadata = this.metadataService.getBox();
    this.interval = setInterval(() => {
      this.update();
    }, 15000); // 15 Seconds
  }

  async ngOnInit(): Promise<void> {
    const currentdate = new Date();
    this.date = formatDate(currentdate, 'yyyy-MM-dd', navigator.language);

    await this.dashboardService
      .getKitting(formatDate(this.date, 'yyyyMMdd', navigator.language))
      .toPromise()
      .then(
        (res: kittingDatalakeBox[]) => {
          let available = 0,
            suspended = 0,
            opened = 0,
            closed = 0,
            dispatched = 0;

          res.forEach(item => {
            available += item.status === 0 ? 1 : 0;
            suspended += item.status === 1 ? 1 : 0;
            opened += item.status === 2 ? 1 : 0;
            closed += item.status === 3 ? 1 : 0;
            closed += item.status === 4 ? 1 : 0;
            dispatched += item.status === 5 ? 1 : 0;
          });

          this.boxes = res;
          this.total = available + opened + suspended + closed + dispatched;
          this.boxQuantityPerState.push(available);
          this.boxQuantityPerState.push(opened);
          this.boxQuantityPerState.push(suspended);
          this.boxQuantityPerState.push(closed);
          this.boxQuantityPerState.push(dispatched);
        },
        error => {
          const code = error.error.code ? error.error.code : error.code ? error.code : '-1';
          this.toast.error(this.errorMessageService.translate(code));

          this.total = 0;
          this.boxQuantityPerState.push(0);
          this.boxQuantityPerState.push(0);
          this.boxQuantityPerState.push(0);
          this.boxQuantityPerState.push(0);
          this.boxQuantityPerState.push(0);
        }
      );

    await this.dashboardService
      .getExpeditions()
      .toPromise()
      .then(
        expeditions => {
          expeditions.map(expedition => {
            expedition.expeditionDate += 'Z';
            if (expedition.inTruckBoxCount === expedition.allBoxCount) this.endedExpeditions.push(expedition);
            else if (expedition.inTruckBoxCount > 0) this.activeExpeditions.push(expedition);
            else this.othersExpeditions.push(expedition);
          });
        },
        error => {
          const code = error.error.code ? error.error.code : error.code ? error.code : '-1';
          this.toast.error(this.errorMessageService.translate(code));
        }
      );
  }

  ngOnDestroy(): void {
    if (this.interval) clearInterval(this.interval);
  }

  filterBumpers(box: kittingDatalakeBox): boolean {
    console.log(box);
    return box.sequenceType === 'bumper' && (box.status === 1 || box.status === 2);
  }

  filterDeflectors(box: kittingDatalakeBox): boolean {
    return box.sequenceType === 'deflector' && (box.status === 1 || box.status === 2);
  }

  filterMudguards(box: kittingDatalakeBox): boolean {
    console.log(box);
    return box.sequenceType === 'mudguard' && (box.status === 1 || box.status === 2);
  }

  async update(): Promise<void> {
    await this.dashboardService
      .getKitting(formatDate(this.date, 'yyyyMMdd', navigator.language))
      .toPromise()
      .then(
        res => {
          let available = 0,
            suspended = 0,
            opened = 0,
            closed = 0,
            dispatched = 0;

          res.forEach(item => {
            available += item.status === 0 ? 1 : 0;
            suspended += item.status === 1 ? 1 : 0;
            opened += item.status === 2 ? 1 : 0;
            closed += item.status === 3 ? 1 : 0;
            closed += item.status === 4 ? 1 : 0;
            dispatched += item.status === 5 ? 1 : 0;
          });

          this.boxes = [];
          this.total = 0;
          this.boxQuantityPerState = [];

          this.boxes = res;
          this.total = available + opened + suspended + closed + dispatched;
          this.boxQuantityPerState.push(available);
          this.boxQuantityPerState.push(opened);
          this.boxQuantityPerState.push(suspended);
          this.boxQuantityPerState.push(closed);
          this.boxQuantityPerState.push(dispatched);
        },
        error => {
          const code = error.error.code ? error.error.code : error.code ? error.code : '-1';
          this.toast.error(this.errorMessageService.translate(code));

          this.total = 0;
          this.boxQuantityPerState.push(0);
          this.boxQuantityPerState.push(0);
          this.boxQuantityPerState.push(0);
          this.boxQuantityPerState.push(0);
          this.boxQuantityPerState.push(0);
        }
      );

    await this.dashboardService
      .getExpeditions()
      .toPromise()
      .then(
        expeditions => {
          this.endedExpeditions = [];
          this.activeExpeditions = [];
          this.othersExpeditions = [];

          expeditions.map(expedition => {
            expedition.expeditionDate += 'Z';
            if (expedition.inTruckBoxCount === expedition.allBoxCount) this.endedExpeditions.push(expedition);
            else if (expedition.inTruckBoxCount > 0) this.activeExpeditions.push(expedition);
            else this.othersExpeditions.push(expedition);
          });
        },
        error => {
          const code = error.error.code ? error.error.code : error.code ? error.code : '-1';
          this.toast.error(this.errorMessageService.translate(code));
        }
      );
  }
}
