/*
 * File: login.service.ts
 * Project: boxpart-console
 * File Created: Thursday, 14th October 2021 10:48:30 am
 * Author: Jorge Herrera (jfelix@vonbraunlabs.com.br)
 * -----
 * Copyright 2021 - Von Braun Labs.
 * -----
 * HISTORY:
 * Date      	By	Comments
 * ----------	---	---------------------------------------------------------
 * 2021-12-14	BL	Get role from current user.
 * 2021-10-14	JH	Authentication service local.
 * 2021-11-11	JH	Applied typescrypt lint check.
 */

import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import jwtDecode, { JwtPayload } from 'jwt-decode';

import { environment } from '@env';
import { AuthData } from '@atypes/auth.types';
import { Observable } from 'rxjs';

/** API URL defined in environment files. */
const API = environment.applicationApiUrl;

/** Provides application authentication services. */
@Injectable()
export class LoginService {
  constructor(private http: HttpClient) {}

  /**
   * Get the remote service credentials
   * @returns an AuthData promise.
   */
  public login(login: string, password: string): Observable<AuthData> {
    const payload = {
      login: login,
      password: password,
    };

    return this.http.post<AuthData>(`${API}/login`, payload);
  }

  /** Save session data on storage */
  public saveAuthData(authData: AuthData): void {
    localStorage.setItem('authData', JSON.stringify(authData));
  }

  /** Retrieve session data. */
  public loadAuthData(): AuthData {
    const authDataStr = localStorage.getItem('authData') || '{}';
    const authData = Object.assign(new AuthData(), JSON.parse(authDataStr));
    return authData;
  }

  /** Check if current session is valid. */
  public isLogged(): boolean {
    const authData = this.loadAuthData();
    const authToken = authData?.token || '';

    // Check token expiration timeout.
    if (authToken && (jwtDecode<JwtPayload>(authToken).exp || 0) >= Date.now() / 1000) {
      return true;
    }

    return false;
  }

  /** Cleans the stored authentication credentials. */
  public logout(): void {
    localStorage.clear();
  }

  /** Get user role */
  public getRoles(): string[] | undefined {
    const authData: AuthData = this.loadAuthData();
    return authData.roles;
  }

  public isAdmin(): boolean | undefined {
    return this.getRoles()?.includes('admin');
  }

  public isLogs(): boolean | undefined {
    return this.getRoles()?.includes('logs');
  }

  public isDev(): boolean | undefined {
    return this.getRoles()?.includes('dev');
  }

  public getUser(): string | undefined {
    if (this.isLogged()) {
      const authData = this.loadAuthData();
      return authData.login;
    }
    return '';
  }
}
