<!--
File: login.component.html
Project: boxpart-console
File Created: Thursday, 14th October 2021 10:16:01 am
Author: Jorge Herrera (jfelix@vonbraunlabs.com.br)
-----
Copyright 2021 - Von Braun Labs.
-----
HISTORY:
Date      	By	Comments
----------	---	---------------------------------------------------------
2021-10-25	TC	Translate language (english to portuguese)
2021-10-14	JH	Login page layout.
2021-11-10	JH	Added I18N support.
-->

<div class="loginArea">
  <h2 class="text-center">Login</h2>
  <form class="form" [formGroup]="loginForm" (ngSubmit)="login()">
    <div class="mb-3">
      <a>{{ 'login.username' | translate }}:</a>
      <input class="form-control" formControlName="login" required />
    </div>
    <div class="mb-3">
      <a>{{ 'login.password' | translate }}:</a>
      <input type="password" class="form-control" formControlName="password" required />
    </div>
    <div class="d-grid gap-2">
      <button type="submit" class="btn btn-primary btn-vydence ml-auto" [disabled]="requesting">
        {{ 'login.enter' | translate }}
      </button>
      <!-- <a class="ml-3" href="./home">Cancel</a> -->
    </div>
  </form>
</div>
