import { STEP_STATE, StepState } from '@angular/cdk/stepper';
import { Component, Inject, LOCALE_ID, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { ReportDto } from '@atypes/report.types';
import { NgbCalendar, NgbDate, NgbDateParserFormatter, NgbDateStruct } from '@ng-bootstrap/ng-bootstrap';
import { TranslateService } from '@ngx-translate/core';
import { AppToastService } from '@services/apptoast.service';
import { NgbDateCustomParserFormatter } from '@services/date-parse-formatter.service';
import { ErrorMessageService } from '@services/error-message.service';
import { ReportService } from '@services/report.service';
import { DataTableDirective } from 'angular-datatables';
import { Subject } from 'rxjs';

@Component({
  selector: 'app-generate-reports',
  templateUrl: './generate-reports.component.html',
  styleUrls: ['./generate-reports.component.css'],
})
export class GenerateReportsComponent implements OnInit, OnDestroy {
  chips = new Map<string, boolean>([
    ['year', false],
    ['month', false],
    ['day', false],
    ['workShift', false],
    ['hour', false],
  ]);

  listPopIds = '';
  selectedShift = '';

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  dtTrigger: Subject<any> = new Subject<any>();

  hoveredDate: NgbDate | null = null;

  fromDate: NgbDate | null;
  toDate: NgbDate | null;
  maxDate: NgbDate | null;

  generatingReport = false;

  @ViewChild(DataTableDirective, { static: false })
  dtElement: DataTableDirective | undefined;

  constructor(
    @Inject(LOCALE_ID) public locale: string,
    private calendar: NgbCalendar,
    public formatter: NgbDateParserFormatter,
    private translate: TranslateService,
    private reportService: ReportService,
    private toast: AppToastService,
    private errorMessageService: ErrorMessageService
  ) {
    this.fromDate = calendar.getPrev(calendar.getToday(), 'm', 1);
    this.toDate = calendar.getNext(calendar.getToday(), 'm', 11);
    this.maxDate = calendar.getNext(this.fromDate, 'y', 1);
  }

  ngOnInit() {
    return;
  }

  ngOnDestroy(): void {
    // Do not forget to unsubscribe the event
    this.dtTrigger.unsubscribe();
  }

  isHovered(date: NgbDate) {
    return (
      this.fromDate && !this.toDate && this.hoveredDate && date.after(this.fromDate) && date.before(this.hoveredDate)
    );
  }

  isInside(date: NgbDate) {
    return this.toDate && date.after(this.fromDate) && date.before(this.toDate);
  }

  isRange(date: NgbDate) {
    return (
      date.equals(this.fromDate) ||
      (this.toDate && date.equals(this.toDate)) ||
      this.isInside(date) ||
      this.isHovered(date)
    );
  }

  validateInput(currentValue: NgbDate | null, input: string): NgbDate | null {
    const parsed = this.formatter.parse(input);
    return parsed && this.calendar.isValid(NgbDate.from(parsed)) ? NgbDate.from(parsed) : currentValue;
  }

  async onDateSelection(date: NgbDate) {
    console.log(date);
    if (!this.fromDate && !this.toDate) {
      this.fromDate = date;
    } else if (this.fromDate && !this.toDate && date && date.after(this.fromDate)) {
      this.toDate = date;
    } else {
      this.toDate = null;
      this.fromDate = date;
    }
    this.maxDate = this.calendar.getNext(this.fromDate, 'y', 1);
    NgbDateCustomParserFormatter.formatDate(date);
  }

  onClickChip(chip: string) {
    this.chips.forEach((_value, key) => this.chips.set(key, false));
    this.chips.set(chip, true);
  }

  dateToString(date: NgbDate | null) {
    if (date) return `${date.day}/${date.month}/${date.year}`;
  }

  workShiftToString(valueShift: string): string {
    switch (valueShift) {
      case '1':
        return this.translate.instant('generate-reports.shift1');
      case '2':
        return this.translate.instant('generate-reports.shift2');
      case 'all':
        return this.translate.instant('generate-reports.allShifts');
      default:
        return this.translate.instant('generate-reports.shiftNone');
    }
  }

  groupByToString() {
    let strGroups: string[] = [];
    for (const [key, value] of this.chips) {
      if (value) {
        strGroups = strGroups.concat(`${this.translate.instant(`generate-reports.${key}`)} `);
      }
    }
    return strGroups;
  }

  groupByToDto(): number {
    return Array.from(this.chips).findIndex(([, value]) => value) + 1;
  }

  isDataValid(): boolean {
    return (
      this.fromDate !== null &&
      this.fromDate !== undefined &&
      this.toDate !== null &&
      this.toDate !== undefined &&
      this.listPopIds !== '' &&
      this.selectedShift !== '' &&
      this.groupByToString().length > 0
    );
  }

  defineStepState(): StepState {
    return this.isDataValid() ? STEP_STATE.DONE : STEP_STATE.EDIT;
  }

  formatDate(date: NgbDateStruct | null): string {
    return date ? date.year + ('0' + date.month).slice(-2) + ('0' + date.day).slice(-2) : '';
  }

  selectedShiftToDto(): 'T1' | 'T2' | '' {
    if (this.selectedShift === '1') return 'T1';
    if (this.selectedShift === '2') return 'T2';
    return '';
  }

  async submitReport() {
    if (!this.isDataValid()) return;

    this.generatingReport = true;

    try {
      const reportDto: ReportDto = {
        initialDate: this.formatDate(this.fromDate),
        endDate: this.formatDate(this.toDate),
        groupBy: this.groupByToDto(),
        listPopids: this.listPopIds === this.translate.instant('generate-reports.yes'),
        shift: this.selectedShiftToDto(),
      };

      const blob = await this.reportService.generateReport(reportDto);

      const blobURL = URL.createObjectURL(blob);

      window.open(blobURL, '_blank');

      URL.revokeObjectURL(blobURL);

      this.toast.success(this.translate.instant('generate-reports.reportGeneratedSuccessfully'));
    } catch {
      this.toast.error(this.translate.instant('generate-reports.errorGeneratingReport'));
    } finally {
      this.generatingReport = false;
    }
  }
}
