/* eslint-disable @typescript-eslint/no-explicit-any */
import { formatDate } from '@angular/common';
import { Component, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { FlatLogEntry, LogEntry } from '@atypes/logs.types';
import { TranslateService } from '@ngx-translate/core';
import { AppToastService } from '@services/apptoast.service';
import { LogsService } from '@services/logs.service';
import { MetadataService } from '@services/metadata.service';
import { SynchronizeService } from '@services/synchronize.service';
import { DataTableDirective } from 'angular-datatables';
import { Subject } from 'rxjs';

@Component({
  selector: 'app-flat-files-logs',
  templateUrl: './flat-files-logs.component.html',
  styleUrls: ['./flat-files-logs.component.css'],
})
export class FlatFilesLogsComponent implements OnInit, OnDestroy {
  isLoading = false;
  logsData: FlatLogEntry[] = [];
  localData: FlatLogEntry[] = [];
  startDate: string;
  endDate: string;
  logMetaData: any;
  errorsMetaData: any;
  dtOptions;
  selectedStatus = '';
  search = '';
  displayFilters = false;

  private flatEventDetailNumbers = [4006, 4007, 4008, 2011, 4009];
  @ViewChild(DataTableDirective) dtElement!: DataTableDirective;

  dtTrigger: Subject<any> = new Subject<any>();

  constructor(
    private logsService: LogsService,
    private translate: TranslateService,
    private metadataService: MetadataService,
    private synchroService: SynchronizeService,
    private toastService: AppToastService
  ) {
    const now = new Date();
    now.setDate(now.getDate() - 15);
    this.startDate = formatDate(now, 'yyyy-MM-dd', navigator.language);
    now.setMonth(now.getMonth() + 1);
    this.endDate = formatDate(now, 'yyyy-MM-dd', navigator.language);

    this.logMetaData = this.metadataService.getLog();
    this.errorsMetaData = this.metadataService.getErrors();

    this.dtOptions = {
      pagingType: 'full_numbers',
      pageLength: 10,
      order: [],
      columnDefs: [{ type: 'date', targets: 0 }],
      language: this.translate.instant('logs'),
      dom: 'ltipr',
    };

    this.getLogsData();
  }

  ngOnInit(): void {
    return;
  }

  ngOnDestroy(): void {
    this.dtTrigger.unsubscribe();
  }

  refreshData() {
    this.dtElement?.dtInstance.then((dtInstance: DataTables.Api) => {
      // Destroy the table first
      dtInstance.destroy();

      this.logsData = [];

      this.getLogsData();
    });
  }

  private filterData(logData: FlatLogEntry) {
    if (this.startDate && this.endDate) {
      const startDate = new Date(this.startDate);
      const endDate = new Date(this.endDate);
      const logDate = new Date(logData.timestamp);

      logDate.setHours(0);
      logDate.setMinutes(0);
      logDate.setSeconds(0);
      endDate.setHours(23);
      endDate.setMinutes(59);
      endDate.setSeconds(59);
      endDate.setDate(endDate.getDate() + 1);

      const status = logData.eventDetailNumber === 4006;
      const selectedStatus = this.selectedStatus === 'ok';

      if (logDate >= startDate && logDate <= endDate && (this.selectedStatus === '' || selectedStatus === status)) {
        return true;
      }

      return false;
    }
  }

  private getLogsData() {
    this.isLoading = true;

    this.logsService.retrieveLogEntries().subscribe(resp => {
      this.logsData = [];
      this.localData = [];
      resp
        .filter(log => this.flatEventDetailNumbers.includes(log.eventDetailNumber))
        .forEach(log => {
          const flatLog: FlatLogEntry = {
            ...log,
            timestamp: this.extractLocaleString(log.timestamp + 'Z'),
            fileName: this.extractFileName(log.data),
            status: this.extractStatus(log.eventDetailNumber),
            details: this.extractDetails(log),
          };

          this.localData.push(flatLog);
        });

      this.logsData = this.localData;

      this.isLoading = false;
      this.dtTrigger.next();
    });
  }

  onDateChange() {
    const startDate = new Date(this.startDate);
    const endDate = new Date(this.endDate);

    const difBetweenDates = endDate.getTime() - startDate.getTime();

    if (difBetweenDates >= 2.628e9 || difBetweenDates <= 0) {
      startDate.setMonth(startDate.getMonth() + 1);
      this.endDate = formatDate(startDate, 'yyyy-MM-dd', navigator.language);
    }
    this.applyFilters();
  }

  applyFilters() {
    this.dtElement?.dtInstance.then((dtInstance: DataTables.Api) => {
      // Destroy the table first
      dtInstance.destroy();

      this.logsData = this.localData.filter(logData => this.filterData(logData));

      // Call the dtTrigger to rerender again
      this.dtTrigger.next();
    });
  }

  applySearch() {
    this.dtElement.dtInstance.then(api => {
      api.search(this.search).draw();
    });
  }

  changeDisplayFilters() {
    this.displayFilters = !this.displayFilters;
  }

  getMaxDate() {
    if (this.startDate) {
      const date = new Date(this.startDate);
      date.setMonth(date.getMonth() + 1);
      return formatDate(date, 'yyyy-MM-dd', navigator.language);
    }
  }

  synchronizeFiles() {
    this.synchroService.synchronizeFlatFiles().subscribe({
      complete: () => {
        this.toastService.success(this.translate.instant('flatLogs.syncDone'));
        this.refreshData();
      },
    });
  }

  private extractLocaleString(dateString: string): string {
    if (!dateString) {
      return '';
    } else {
      return new Date(dateString).toISOString();
    }
  }

  private extractFileName(data: string): string {
    if (!data || data[0] !== '{') return this.translate.instant('flatLogs.errors.withoutName');

    const json = JSON.parse(data);

    if (Object.keys(json).includes('Filename') && json.Filename) {
      return json.Filename;
    }

    if (Object.keys(json).includes('flatFileIdentifier') && json.flatFileIdentifier.Filename) {
      return json.flatFileIdentifier.Filename;
    }

    return this.translate.instant('flatLogs.errors.withoutName');
  }

  private extractStatus(code: number): string {
    if (code === 4006) {
      return this.translate.instant('flatLogs.states.ok');
    }
    return this.translate.instant('flatLogs.states.failure');
  }

  private extractDetails(log: LogEntry): string {
    if (!log) {
      return this.translate.instant('flatLogs.errors.noDetails');
    } else {
      let innerHTML = '';

      if (log.data[0] === '{') {
        const jsonData = JSON.parse(log.data);

        if (Object.keys(jsonData).includes('defaultPath')) {
          innerHTML += this.translate.instant('flatLogs.defaultPath') + '</br>';
        }
        if (Object.keys(jsonData).includes('sequenceType')) {
          innerHTML +=
            '<strong>' +
            this.translate.instant('flatLogs.box') +
            ':</strong> ' +
            this.translate.instant('logs.data.refNumber') +
            ' ' +
            jsonData.refNumber +
            ', ' +
            this.translate.instant('logs.data.sequenceType') +
            ' ' +
            jsonData.sequenceType +
            ', ' +
            this.translate.instant('logs.data.supplier') +
            ' ' +
            jsonData.supplier +
            ', ' +
            formatDate(Date.parse(jsonData.sequenceDate), 'shortDate', navigator.language) +
            '</br>';
        }
        if (Object.keys(jsonData).includes('partNumber')) {
          innerHTML +=
            '<strong>' +
            this.translate.instant('common.part') +
            ': </strong>' +
            this.translate.instant('common.partNumber') +
            ': ' +
            jsonData.partNumber +
            ', ' +
            this.translate.instant('common.colorRef') +
            ': ' +
            jsonData.colorNumber +
            '</br>';
        }
        if (Object.keys(jsonData).includes('boxIdentifier')) {
          innerHTML +=
            '<strong>' +
            this.translate.instant('logs.data.assemblyPack') +
            ':</strong> ' +
            this.translate.instant('logs.data.refNumber') +
            ' ' +
            (jsonData.boxIdentifier as Record<string, unknown>).refNumber +
            ', ' +
            this.translate.instant('logs.data.sequenceType') +
            ' ' +
            (jsonData.boxIdentifier as Record<string, unknown>).sequenceType +
            ', ' +
            this.translate.instant('logs.data.supplier') +
            ' ' +
            (jsonData.boxIdentifier as Record<string, unknown>).supplier +
            ', ' +
            formatDate(
              Date.parse((jsonData.boxIdentifier as Record<string, string>).sequenceDate),
              'shortDate',
              navigator.language
            ) +
            '</br>';
        }
      }

      if (log.code === 3015) {
        if (navigator.language === 'pt-BR') {
          innerHTML += this.errorsMetaData[log.code].messagePT;
        } else {
          innerHTML += this.errorsMetaData[log.code].message;
        }
      } else {
        const eventDetail = this.logMetaData.eventDetailDict[log.eventDetailNumber];
        if (navigator.language === 'pt-BR') {
          innerHTML += eventDetail.namePT;
        } else {
          innerHTML += eventDetail.nameEN;
        }
      }
      return innerHTML;
    }
  }
}
