<div class="logistic-container">
  <div class="filter-area shadowCard">
    <input
      type="date"
      class="form-control"
      placeholder="Data final"
      name="endDateInput"
      [(ngModel)]="inputDate"
      (change)="updateChartDate(inputDate)" />
  </div>
  <div class="bumper-chart shadowCard">
    <canvas baseChart [data]="barChartDataBumper" [options]="barChartOptionsBumper" [type]="barChartType"> </canvas>
  </div>
  <div class="deflector-chart shadowCard">
    <canvas baseChart [data]="barChartDataDeflector" [options]="barChartOptionsDeflector" [type]="barChartType">
    </canvas>
  </div>
  <div class="mudguard-chart shadowCard">
    <canvas baseChart [data]="barChartDataMudguard" [options]="barChartOptionsMudguard" [type]="barChartType"> </canvas>
  </div>
</div>
