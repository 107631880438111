import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { PartAvailable } from '@atypes/quality.types';
import { environment } from '@env';
import { LoginService } from './login.service';

/** API URL defined in environment files. */
const API = environment.applicationApiUrl;

@Injectable({
  providedIn: 'root',
})
export class QualityService {
  constructor(private http: HttpClient, private login: LoginService) {}

  private prepareRequest() {
    const auth = this.login.loadAuthData();
    const headers = new HttpHeaders().set('Authorization', `bearer ${auth.token}`);
    return headers;
  }

  getAvailableParts(pageNum: number, pageTam: number) {
    const authParam = this.prepareRequest();
    const params = new HttpParams({
      fromObject: {
        pageNum: pageNum,
        pageTam: pageTam
      }
  });

    return this.http.get<PartAvailable[]>(`${API}/quality/available`, {
      headers: authParam,
      params
    });
  }


  deletePart(partId: number, partNumber: string, colorNumber: string, timestamp: string){
    const authParam = this.prepareRequest();
    const payload = {
      partId: partId,
      partNumber: partNumber,
      colorNumber: colorNumber,
      timestamp: timestamp
    };

    const options = { headers: authParam, body: payload}

    return this.http.delete(`${API}/quality/cancel`, options);

  }

  cleanInventory(){
    const headers = this.prepareRequest();

    return this.http.post(`${API}/quality/cleanInventory`, null , {headers});
  }
}
