<div class="geral-group">
  <div class="search-container">
    <input
      class="form-control me-2"
      type="text"
      name="searchInput"
      placeholder="{{ 'sequences.search' | translate }}"
      aria-label="Search"
      [(ngModel)]="search"
      (ngModelChange)="applySearch()"
      autocomplete="off" />

    <button class="btn btn-primary me-2" (click)="changeDisplayFilters()">
      <i class="fas fa-filter"></i>
    </button>

    <button class="btn btn-primary me-2" (click)="synchronizeFiles()">
      {{ 'flatLogs.synchronize' | translate }}
    </button>

    <button class="btn btn-primary me-2" (click)="refreshData()">
      <img class="button-icon" src="../../../assets/images/icons/refresh-Icon.svg" alt="refresh-icon" />
    </button>
  </div>

  <div class="filter-controls" *ngIf="displayFilters">
    <select class="form-control" [(ngModel)]="selectedStatus" (change)="applyFilters()">
      <option value="" selected="true">{{ 'common.status' | translate }}</option>
      <option value="ok">{{ 'common.ok' | translate }}</option>
      <option value="failure">{{ 'common.failure' | translate }}</option>
    </select>
    <div class="date-picker">
      <input type="date" class="form-control" [(ngModel)]="startDate" (change)="onDateChange()" />

      <span class="ml">{{ 'common.until' | translate }}: </span>

      <input
        type="date"
        class="form-control ml"
        [(ngModel)]="endDate"
        [min]="startDate"
        [max]="getMaxDate()"
        (change)="applyFilters()" />
    </div>
  </div>

  <div class="loading" *ngIf="isLoading">
    <div class="spinner-border text-primary" role="status"></div>
  </div>

  <br />

  <div class="row">
    <div class="col-md-12">
      <table
        datatable
        [dtOptions]="dtOptions"
        [dtTrigger]="dtTrigger"
        class="row-border hover"
        aria-label="flat-files-logs">
        <thead *ngIf="!isLoading">
          <tr>
            <th>{{ 'flatLogs.timestamp' | translate }}</th>
            <th>{{ 'flatLogs.fileName' | translate }}</th>
            <th>{{ 'flatLogs.status' | translate }}</th>
            <th>{{ 'flatLogs.details' | translate }}</th>
          </tr>
        </thead>
        <tbody>
          <tr
            *ngFor="let log of logsData"
            style="height: 3.5rem"
            [ngStyle]="{ 'background-color': log.status === 'OK' ? '#d9fdd8' : '#fdded8' }">
            <td>{{ log.timestamp | date: 'short' }}</td>
            <td>{{ log.fileName }}</td>
            <td>{{ log.status }}</td>
            <td [innerHTML]="log.details"></td>
          </tr>
        </tbody>
      </table>
    </div>
  </div>
</div>
