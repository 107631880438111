<div class="content">
  <div class="row greyDiv">
    <h1>{{ 'generate-reports.title' | translate }}</h1>
    <!-- Date Picker -->
    <label>{{ 'generate-reports.selectPeriod' | translate }}:</label>
    <div class="col-lg-auto col-md-12">
      <div class="dp-hidden position-absolute">
        <div>
          <input
            name="datepicker"
            class="form-control"
            ngbDatepicker
            #datepicker="ngbDatepicker"
            [autoClose]="'outside'"
            (dateSelect)="onDateSelection($event)"
            [displayMonths]="2"
            [dayTemplate]="t"
            outsideDays="hidden"
            [startDate]="fromDate!"
            tabindex="-1"
            [maxDate]="maxDate!" />
          <ng-template #t let-date let-focused="focused">
            <span
              class="custom-day"
              [class.focused]="focused"
              [class.range]="isRange(date)"
              [class.faded]="isHovered(date) || isInside(date)"
              (mouseenter)="hoveredDate = date"
              (mouseleave)="hoveredDate = null">
              {{ date.day }}
            </span>
          </ng-template>
        </div>
      </div>
      <div class="input-group">
        <input
          #dpFromDate
          disabled
          class="form-control"
          placeholder="yyyy-mm-dd"
          name="dpFromDate"
          [value]="formatter.format(fromDate)"
          (input)="fromDate = validateInput(fromDate, dpFromDate.value)" />
        <button class="btn btn-outline-secondary" (click)="datepicker.toggle()" type="button">
          <i class="far fa-calendar-alt"></i>
        </button>
      </div>
    </div>
    <div class="col-lg-auto col-md-12">
      <div class="input-group">
        <input
          #dpToDate
          disabled
          class="form-control"
          placeholder="yyyy-mm-dd"
          name="dpToDate"
          [value]="formatter.format(toDate)"
          (input)="toDate = validateInput(toDate, dpToDate.value)" />
        <button class="btn btn-outline-secondary" (click)="datepicker.toggle()" type="button">
          <i class="far fa-calendar-alt"></i>
        </button>
      </div>
    </div>
    <!-- Work shift selector -->
    <label>{{ 'generate-reports.selectWorkShift' | translate }}</label>
    <mat-radio-group
      [(ngModel)]="selectedShift"
      required
      class="radio-group"
      color="primary"
      aria-label="Select an option">
      <mat-radio-button class="margin-button" value="1">
        {{ 'generate-reports.shift1' | translate }}
      </mat-radio-button>
      <mat-radio-button class="margin-button" value="2">
        {{ 'generate-reports.shift2' | translate }}
      </mat-radio-button>
      <mat-radio-button class="margin-button" value="all">
        {{ 'generate-reports.allShifts' | translate }}
      </mat-radio-button>
    </mat-radio-group>
    <!-- List pop ids -->
    <label>{{ 'generate-reports.listPopIds' | translate }}</label>
    <mat-radio-group
      [(ngModel)]="listPopIds"
      [required]="true"
      class="radio-group"
      color="primary"
      aria-label="Select an option">
      <mat-radio-button class="margin-button" [value]="'generate-reports.yes' | translate">
        {{ 'generate-reports.yes' | translate }}
      </mat-radio-button>
      <mat-radio-button class="margin-button" [value]="'generate-reports.no' | translate">
        {{ 'generate-reports.no' | translate }}
      </mat-radio-button>
    </mat-radio-group>
    <!-- Group by list -->
    <label>
      {{ 'generate-reports.groupBy' | translate }}
    </label>
    <mat-chip-list class="margin-content" aria-label="Group selection">
      <mat-chip [selected]="chips.get('year')" (click)="onClickChip('year')">
        {{ 'generate-reports.year' | translate }}
      </mat-chip>
      <mat-chip [selected]="chips.get('month')" (click)="onClickChip('month')">
        {{ 'generate-reports.month' | translate }}
      </mat-chip>
      <mat-chip [selected]="chips.get('day')" (click)="onClickChip('day')">
        {{ 'generate-reports.day' | translate }}
      </mat-chip>
      <mat-chip [selected]="chips.get('workShift')" (click)="onClickChip('workShift')">
        {{ 'generate-reports.workShift' | translate }}
      </mat-chip>
      <mat-chip [selected]="chips.get('hour')" (click)="onClickChip('hour')">
        {{ 'generate-reports.hour' | translate }}
      </mat-chip>
    </mat-chip-list>
    <div class="align-right-button">
      <button
        mat-raised-button
        *ngIf="!generatingReport"
        color="primary"
        [disabled]="!isDataValid()"
        (click)="submitReport()">
        {{ ('generate-reports.createReport' | translate).toUpperCase() }}
      </button>

      <div *ngIf="generatingReport" class="generating-report">
        <div class="spinner-border text-primary" role="status">
          <span class="visually-hidden">Loading...</span>
        </div>

        <span> {{ 'generate-reports.generatingReport' | translate }}... </span>
      </div>
    </div>
  </div>
</div>
