import { Component, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { MatPaginator, PageEvent } from '@angular/material/paginator';
import { MarketRow, PartAvailable } from '@atypes/quality.types';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { TranslateService } from '@ngx-translate/core';
import { AppToastService } from '@services/apptoast.service';
import { ExportCSVService } from '@services/export-csv.service';
import { MetadataService } from '@services/metadata.service';
import { QualityService } from '@services/quality.service';
import { LoginService } from 'src/app/services/login.service';

type DeleteReturn = {
  success: PartAvailable[];
  error: PartAvailable[];
};

@Component({
  selector: 'app-market',
  templateUrl: './market.component.html',
  styleUrls: ['./market.component.css']
})
export class MarketComponent implements OnInit, OnDestroy {
  isAdmin: boolean | undefined = false;
  searchFilter = '';
  allParts: MarketRow[] = [];
  displayedData: MarketRow[] = [];
  filteredParts: MarketRow[] = [];
  isLoading = false;

  deleteReturn: DeleteReturn = {
    success: [],
    error: []
  };

  @ViewChild('paginator', { static: true }) paginator: MatPaginator | undefined;
  totalRecords = 0;
  pageSize = 25;
  pageIndex = 0;

  // Sort sequence columns in the table
  key?: string = 'parNumber'; // Initializing sort method (using date field)
  reverse?: boolean = true;

  partKey?: string = 'timestamp'; // Initializing sort method (using date field)
  partReverse?: boolean = true;

  constructor(
    private translate: TranslateService,
    private qualityService: QualityService,
    private metadataService: MetadataService,
    private exportService: ExportCSVService,
    private toastService: AppToastService,
    private modalService: NgbModal,
    private loginService: LoginService
  ) {}

  ngOnDestroy(): void {
    this.allParts = [];
    this.displayedData = [];
    this.filteredParts = [];
  }

  ngOnInit(): void {
    this.isAdmin = this.loginService.getRoles()?.includes('admin');
    this.isLoading = true;
    this.qualityService.getAvailableParts(1, 10000).subscribe({
      next: parts => {
        this.createAllPartList(parts);
        this.getPagedData();
        this.isLoading = false;
      },
    });
    return;
  }

  // From part number, return how much parts have the same part number from parts list.
  private countUniquePartNumberAndColor(partNumber: string, colorNumber: string, parts: PartAvailable[]): number {
    return parts.filter(part => part.partNumber === partNumber && part.colorNumber === colorNumber).length;
  }

  private getColorFromCode(colorCode: string): string {
    return this.metadataService.colors[colorCode].colorCode;
  }

  private createAllPartList(allParts: PartAvailable[]): void {
    const rows: MarketRow[] = [];
    const singlePartsFromPartNumber: PartAvailable[] = [];

    allParts.forEach(part => {
      if (
        singlePartsFromPartNumber.findIndex(
          singlePart => singlePart.partNumber === part.partNumber && singlePart.colorNumber === part.colorNumber
        ) == -1
      ) {
        singlePartsFromPartNumber.push(part);
      }
    });

    singlePartsFromPartNumber.forEach(singlePart => {
      const row: MarketRow = {
        partNumber: singlePart.partNumber,
        pscaCode: singlePart.pscaCode,
        color: this.getColorFromCode(singlePart.colorNumber).toUpperCase(),
        quantity: this.countUniquePartNumberAndColor(singlePart.partNumber, singlePart.colorNumber, allParts),
        toggle: false,
        Parts: allParts.filter(part => part.colorNumber === singlePart.colorNumber && part.partNumber === singlePart.partNumber)
      };

      rows.push(row);
    });
    this.allParts = rows;
    this.filterData();
  }

  private filterData(filter = ''): void{
    this.filteredParts = this.allParts.filter(marketRow =>
      marketRow.partNumber.toUpperCase().indexOf(filter) > -1
      ||
      marketRow.pscaCode?.toUpperCase().indexOf(filter) > -1
      ||
      marketRow.color.toUpperCase().indexOf(filter) > -1
      ||
      filter === ''
    );

    this.totalRecords = this.filteredParts.length;
    this.getPagedData();
  }

  onChangeSearch() {

    const filter = this.searchFilter.toUpperCase();

    this.filterData(filter);
  }

  async refreshData() {
    this.isLoading = true;
    this.searchFilter = '';
    this.allParts = [];

    await this.qualityService.getAvailableParts(1, 10000).subscribe({
      next: parts => {
        this.createAllPartList(parts);
        this.getPagedData();
        this.isLoading = false;
      },
    });
  }

  /* clearFilter() {
    this.dtElement.dtInstance.then(dataTable => {
      this.searchFilter = '';
      dataTable.search(this.searchFilter).draw();
    });
  } */

  createCSV() {
    if (this.displayedData.length === 0) {
      this.toastService.error(this.translate.instant('export.errors.nullData'));
    } else {
      const csvArray = this.allParts.map(({ partNumber, pscaCode, color, quantity }) => ({
        partNumber,
        pscaCode,
        color,
        quantity,
      }));

      const fileName = `PSCA-Market_Parts(${new Date().toLocaleDateString().replace(RegExp('/', 'g'), '-')})`;
      const headers = [
        this.translate.instant('market.partNumber'),
        this.translate.instant('market.pscaCode'),
        this.translate.instant('market.color'),
        this.translate.instant('market.quantity'),
      ];
      this.exportService.exportCSV(csvArray, fileName, headers);
    }
  }

  async cleanInventory() {
    this.isLoading = true;

    await this.qualityService.cleanInventory().subscribe({
      next: () => {
        this.refreshData();
      }
    });
  }

  // Toggle button for marketRow Table
  marketRowToggle(marketRow: MarketRow): void {
    marketRow.toggle = !marketRow.toggle;
    // If marketRow packs already exists, return
    if (marketRow.Parts?.length) return;
  }

  /** Sorting sequence columns in the table */
  sort(key: string): void {
    this.key = key; // Key for sort
    this.reverse = !this.reverse; // Sort order
  }

  partSort(key: string): void {
    this.partKey = key; // Key for sort
    this.partReverse = !this.partReverse; // Sort order
  }

  /** Select part for delete */
  partCheck(part: PartAvailable): void{
    part.select = !part.select;
  }

  /** Verify parts for delete */
  verifyParts(): boolean{
    // Start disabled
    let disable = true;
    this.displayedData?.map(marketRow => {
      marketRow.Parts?.map( part => {
        if(part.select) disable = false;
      });
    });

    return disable;
  }

  private getPagedData() {
    const startIndex = this.pageIndex * this.pageSize;
    const endIndex = startIndex + this.pageSize;
    this.displayedData = this.filteredParts.slice(startIndex, endIndex);
  }

  pageChangeEvent(event: PageEvent) {
    this.pageIndex = event.pageIndex;
    this.pageSize = event.pageSize;
    this.getPagedData();
  }

  /** Delete parts */
  async deleteParts(content): Promise<void>{

    for(const marketRow of this.displayedData){
      for(const part of marketRow.Parts){
        if(part.select)
          await this.qualityService.deletePart(
            part.partId,
            part.partNumber,
            part.colorNumber,
            part.timestamp.toString()
          ).toPromise().then(
            () => this.deleteReturn.success.push(part)
          ).catch(
            () => this.deleteReturn.error.push(part)
          );
      }
    }

    this.modalService.open(content, {
        ariaLabelledBy: 'Modal deleção',
        centered: true,
        size: 'lg',
      });

    this.refreshData();
  }


}
