<ng-template #content let-modal>
  <div class="modal-header">
    <h5 class="modal-title" style="font-size: 1.5rem; padding-top: 0.8rem" id="exampleModalLabel">Detalhes</h5>
    <button type="button" class="btn-close" aria-label="Close" (click)="modal.dismiss('Cross click')"></button>
  </div>
  <div class="modal-body">
    <div class="log-description">
      <span class="title">Classe:</span>
      <span class="description">{{ modalData.eventClassNumber }}</span>
      <span class="title">Evento:</span>
      <span class="description">{{ modalData.eventDetailNumber }}</span>
      <span class="title">Contexto:</span>
      <span class="description">{{ modalData.contextTag }}</span>
    </div>

    <div class="log-content">
      <h3>Dados:</h3>
      <div class="log-detailed-data">
        <span [innerHTML]="modalData.data" style="white-space: pre-line"></span>
        <br /><br />
        <span [innerHTML]="modalData.detailedData" style="white-space: pre-line"></span>
      </div>
    </div>

    <div class="log-footer">
      <span>Responsável: {{ modalData.login }}</span>
      <span>Data e hora: {{ modalData.timestamp }}</span>
    </div>
  </div>
  <div class="modal-footer">
    <button type="button" class="btn btn-outline-dark" (click)="modal.close('Close click')">Fechar</button>
  </div>
</ng-template>

<div class="row geral-group">
  <div class="col-md-12">
    <div class="form-floating">
      <select
        class="form-select"
        id="floatingSelect"
        aria-label="Select"
        style="width: 13rem"
        (change)="onGravityFilterChange()"
        [(ngModel)]="selectedGravity">
        <option value="" selected>Todos</option>
        <option value="0">Normal</option>
        <option value="1">Moderada</option>
        <option value="2">Alta</option>
      </select>
      <label for="floatingSelect">Nível de gravidade</label>
    </div>
    <br />

    <div class="loading" *ngIf="loading">
      <div class="spinner-border text-primary" role="status">
        <span class="visually-hidden">Loading...</span>
      </div>
    </div>
    <table datatable [dtOptions]="dtOptions" [dtTrigger]="dtTrigger" class="row-border hover">
      <thead *ngIf="!loading">
        <tr>
          <th>{{ 'logs.title.timestamp' | translate }}</th>
          <th>{{ 'logs.title.responsible' | translate }}</th>
          <th>{{ 'logs.title.eventClass' | translate }}</th>
          <th>{{ 'logs.title.eventDetail' | translate }}</th>
          <th>{{ 'logs.title.context' | translate }}</th>
          <th>{{ 'logs.title.data' | translate }}</th>
          <th></th>
        </tr>
      </thead>
      <tbody *ngIf="!loading && logEntries?.length !== 0">
        <tr
          *ngFor="let logEntry of logEntries"
          [ngStyle]="{
            'background-color': logEntry.gravity === 1 ? '#FFF9DB' : logEntry.gravity === 2 ? '#FDDED8' : ''
          }"
          style="height: 3.5rem">
          <td>{{ logEntry.timestamp }}</td>
          <td>{{ logEntry.login }}</td>
          <td>{{ logEntry.eventClassNumber }}</td>
          <td style="width: 10%">{{ logEntry.eventDetailNumber }}</td>
          <td>{{ logEntry.contextTag }}</td>
          <td [innerHTML]="logEntry.data" style="white-space: pre-line"></td>
          <td style="width: 5rem">
            <button
              type="button"
              class="btn btn-primary"
              *ngIf="logEntry.detailedData !== ''"
              (click)="open(content, logEntry)">
              {{ 'logs.title.details' | translate }}
            </button>
          </td>
        </tr>
      </tbody>
      <tbody *ngIf="logEntries?.length === 0">
        <tr>
          <td *ngIf="!loading" colspan="3" class="no-data-available">
            {{ 'logs.title.noData' | translate }}
          </td>
        </tr>
      </tbody>
    </table>
  </div>
</div>
<br />
