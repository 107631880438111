<div class="container">
  <ng-template #content let-modal>
    <div class="modal-header">
      <h5 class="modal-title" style="font-size: 1.5rem; padding-top: 0.8rem" id="exampleModalLabel">
        Lista de romaneio
      </h5>
      <button type="button" class="btn-close" aria-label="Close" (click)="modal.dismiss('Cross click')"></button>
    </div>
    <div class="modal-body">
      <div class="log-description">
        <span class="title">Data e hora:</span>
        <span class="description">{{ transformDateFormatToTable(modalData.dateTime) }}</span>
        <span class="title">Tipo de transporte:</span>
        <span class="description">{{ modalData.transportType }}</span>
        <span class="title">Tipo de veículo:</span>
        <span class="description">{{ modalData.vehicleType }}</span>
      </div>

      <div class="log-content">
        <h3>POPID's nesta lista de romaneio:</h3>
        <div class="log-detailed-data" *ngIf="currentlyPopIdList.length">
          <li style="white-space: pre-line" *ngFor="let entry of currentlyPopIdList">
            {{ entry.popId }}
          </li>
        </div>
        <div class="log-detailed-data" *ngIf="loading">
          <div class="spinner-border text-primary" role="status">
            <span class="visually-hidden">Loading...</span>
          </div>
        </div>
        <div class="log-detailed-data" *ngIf="!currentlyPopIdList.length && !loading">
          Algum erro impediu que os POPID's desta lista de romaneio fossem carregados.
        </div>
      </div>
    </div>
    <div class="modal-footer">
      <button
        type="button"
        class="btn btn-primary"
        [disabled]="!currentlyPopIdList.length"
        (click)="goToPrint(modalData)">
        Imprimir
      </button>
      <button type="button" class="btn btn-outline-dark" (click)="modal.close('Close click')">Fechar</button>
    </div>
  </ng-template>

  <ng-template #remove let-modal>
    <div class="modal-header">
      <h5 class="modal-title" style="font-size: 1.5rem; padding-top: 0.8rem" id="exampleModalLabel">
        Lista de romaneio
      </h5>
      <button type="button" class="btn-close" aria-label="Close" (click)="modal.dismiss('Cross click')"></button>
    </div>
    <div class="modal-body">
      <div class="log-description">
        <span class="title">Data e hora:</span>
        <span class="description">{{ transformDateFormatToTable(modalData.dateTime) }}</span>
        <span class="title">Tipo de transporte:</span>
        <span class="description">{{ modalData.transportType }}</span>
        <span class="title">Tipo de veículo:</span>
        <span class="description">{{ modalData.vehicleType }}</span>
      </div>

      <div class="log-content">
        <h3>POPID's nesta lista de romaneio:</h3>
        <div class="log-detailed-data" *ngIf="currentlyPopIdList.length">
          <li style="white-space: pre-line" *ngFor="let entry of currentlyPopIdList">
            {{ entry.popId }}
          </li>
        </div>
        <div class="log-detailed-data" *ngIf="loading">
          <div class="spinner-border text-primary" role="status">
            <span class="visually-hidden">Loading...</span>
          </div>
        </div>
        <div class="log-detailed-data" *ngIf="!currentlyPopIdList.length && !loading">
          Algum erro impediu que os POPID's desta lista de romaneio fossem carregados.
        </div>
      </div>
    </div>
    <div class="modal-footer">
      <button type="button" class="btn btn-primary" style="background: #eb3737" (click)="removeExpedition(modalData)">
        Excluir expedição
      </button>
      <button type="button" class="btn btn-outline-dark" (click)="modal.close('Close click')">Fechar</button>
    </div>
  </ng-template>

  <form class="row">
    <div class="col-lg-auto col-md-12">
      <div class="dp-hidden position-absolute">
        <div class="input-group">
          <input
            name="datepicker"
            class="form-control"
            ngbDatepicker
            #datepicker="ngbDatepicker"
            [autoClose]="'outside'"
            (dateSelect)="onDateSelection($event)"
            [displayMonths]="2"
            [dayTemplate]="t"
            outsideDays="hidden"
            [startDate]="fromDate!"
            tabindex="-1"
            [maxDate]="maxDate!" />
          <ng-template #t let-date let-focused="focused">
            <span
              class="custom-day"
              [class.focused]="focused"
              [class.range]="isRange(date)"
              [class.faded]="isHovered(date) || isInside(date)"
              (mouseenter)="hoveredDate = date"
              (mouseleave)="hoveredDate = null">
              {{ date.day }}
            </span>
          </ng-template>
        </div>
      </div>
      <div class="input-group">
        <input
          #dpFromDate
          class="form-control"
          placeholder="yyyy-mm-dd"
          name="dpFromDate"
          [value]="formatter.format(fromDate)"
          (input)="fromDate = validateInput(fromDate, dpFromDate.value)" />
        <button class="btn btn-outline-secondary" (click)="datepicker.toggle()" type="button">
          <i class="far fa-calendar-alt"></i>
        </button>
      </div>
    </div>
    <div class="col-lg-auto col-md-12">
      <div class="input-group">
        <input
          #dpToDate
          class="form-control"
          placeholder="yyyy-mm-dd"
          name="dpToDate"
          [value]="formatter.format(toDate)"
          (input)="toDate = validateInput(toDate, dpToDate.value)" />
        <button class="btn btn-outline-secondary" (click)="datepicker.toggle()" type="button">
          <i class="far fa-calendar-alt"></i>
        </button>
      </div>
    </div>

    <div class="col-lg-auto col-md-12 ms-auto justify-content-lg-end">
      <button type="button" class="btn btn-primary" style="width: 100%" (click)="goToNewExpedition()">
        Criar lista de romaneio
      </button>
    </div>
  </form>

  <br /><br />

  <table datatable [dtOptions]="dtOptions" [dtTrigger]="dtTrigger" class="row-border hover">
    <thead>
      <tr>
        <th>Data e hora</th>
        <th>Tipo de transporte</th>
        <th>Tipo de veículo</th>
        <th></th>
      </tr>
    </thead>
    <tbody>
      <tr *ngFor="let expedition of expeditions">
        <td>{{ transformDateFormatToTable(expedition.dateTime) }}</td>
        <td>{{ expedition.transportType }}</td>
        <td>{{ expedition.vehicleType }}</td>
        <td>
          <div ngbDropdown class="d-inline-block">
            <button type="button" class="btn btn-outline-primary btn-sm" id="dropdownBasic1" ngbDropdownToggle>
              Opções
            </button>
            <div ngbDropdownMenu aria-labelledby="dropdownBasic1">
              <button ngbDropdownItem (click)="openDetailModal(content, expedition)">Detalhes</button>
              <button ngbDropdownItem (click)="goToEditExpeditionPage(expedition)">Editar</button>
              <div class="dropdown-divider"></div>
              <button ngbDropdownItem (click)="openDetailModal(remove, expedition)">
                <span style="color: #eb3737">Excluir</span>
              </button>
            </div>
          </div>
        </td>
      </tr>
    </tbody>
  </table>
  <br />
</div>
