<!-- Modal -->
<div class="modal fade" id="marketModal" tabindex="-1" aria-labelledby="marketModalLabel" aria-hidden="true">
  <div class="modal-dialog">
    <div class="modal-content">
      <div class="modal-header">
        <h5 class="modal-title" style="font-size: 1.5rem; margin-top: 1rem; font-weight: 500">Limpar Estoque</h5>
        <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
      </div>
      <div class="modal-body">Você tem certeza que deseja limpar todo estoque do mercado?</div>
      <div class="modal-footer">
        <button type="button" class="btn btn-secondary" data-bs-dismiss="modal">Não</button>
        <button type="button" class="btn btn-danger" (click)="cleanInventory()" data-bs-dismiss="modal">Sim</button>
      </div>
    </div>
  </div>
</div>

<ng-template #content let-modal>
  <div class="modal-header">
    <h5 class="modal-title" style="font-size: 1.5rem; padding-top: 0.8rem" id="exampleModalLabel">Resultado Deleção</h5>
    <button type="button" class="btn-close" aria-label="Close" (click)="modal.dismiss('Cross click')"></button>
  </div>

  <div class="modal-body">
    <div *ngIf="deleteReturn.error.length > 0">
      <div>
        <h3 class="errorTitle">Erro na Deleção</h3>
      </div>
      <table class="table errorDeleting">
        <thead>
          <tr>
            <th>
              {{ 'market.partNumber' | translate }}
            </th>
            <th>
              {{ 'market.color' | translate }}
            </th>
            <th (click)="partSort('timestamp')">
              {{ 'market.timestamp' | translate }} &nbsp; <i class="fa fa-sort"></i>
            </th>
          </tr>
        </thead>
        <tbody>
          <ng-container *ngFor="let part of deleteReturn.error | orderBy: partKey:partReverse">
            <tr>
              <td>{{ part.partNumber }}</td>
              <td>{{ part.colorNumber }}</td>
              <td>{{ part.timestamp }}</td>
            </tr>
          </ng-container>
        </tbody>
      </table>
    </div>

    <div *ngIf="deleteReturn.success.length > 0">
      <div>
        <h3 class="successTitle">Sucesso na Deleção</h3>
      </div>
      <table class="table successDeleting">
        <thead>
          <tr>
            <th>
              {{ 'market.partNumber' | translate }}
            </th>
            <th>
              {{ 'market.color' | translate }}
            </th>
            <th (click)="partSort('timestamp')">
              {{ 'market.timestamp' | translate }} &nbsp; <i class="fa fa-sort"></i>
            </th>
          </tr>
        </thead>
        <tbody>
          <ng-container *ngFor="let part of deleteReturn.success | orderBy: partKey:partReverse">
            <tr>
              <td>{{ part.partNumber }}</td>
              <td>{{ part.colorNumber }}</td>
              <td>{{ part.timestamp }}</td>
            </tr>
          </ng-container>
        </tbody>
      </table>
    </div>
  </div>
  <div class="modal-footer">
    <button type="button" class="btn btn-outline-dark" (click)="modal.close('Close click')">Fechar</button>
  </div>
</ng-template>

<div class="row geral-group">
  <div class="col-md-12">
    <!-- Header of page -->
    <div class="header">
      <h1>{{ 'market.titlePage' | translate }}</h1>
      <div class="header-options">
        <div class="left-option">
          <mat-form-field appearance="outline">
            <mat-label> {{ 'market.search' | translate }} </mat-label>
            <input matInput [(ngModel)]="searchFilter" (ngModelChange)="onChangeSearch()" [disabled]="isLoading" />
            <img
              matSuffix
              class="suffix-icon"
              alt="search icon"
              *ngIf="searchFilter === '' || searchFilter === undefined"
              src="../../../assets/images/icons/search-Icon.svg" />
          </mat-form-field>

          <button mat-raised-button color="primary" class="reload" (click)="refreshData()" [disabled]="isLoading">
            <img class="button-icon" src="../../../assets/images/icons/refresh-Icon.svg" alt="reload icon" />
          </button>
        </div>

        <div class="right-option">
          <button
            mat-raised-button
            color="primary"
            (click)="createCSV()"
            [disabled]="isLoading || allParts.length <= 0">
            {{ 'market.exportData' | translate }}
          </button>
        </div>
        &nbsp;
        <div class="right-option" *ngIf="isAdmin">
          <button
            mat-raised-button
            color="warn"
            data-bs-toggle="modal"
            data-bs-target="#marketModal"
            [disabled]="isLoading || allParts.length <= 0">
            {{ 'market.cleanInventory' | translate }}
          </button>
        </div>
      </div>
    </div>
    <div class="loading" *ngIf="isLoading">
      <div class="spinner-border text-primary" role="status"></div>
    </div>

    <table id="marketTable" *ngIf="!isLoading" class="table market-table" aria-label="Market table">
      <thead>
        <tr>
          <th>&nbsp;</th>
          <th class="centered-text" (click)="sort('partNumber')">
            {{ 'market.partNumber' | translate }} &nbsp;<i class="fa fa-sort"></i>
          </th>
          <th class="centered-text" (click)="sort('pscaCode')">
            {{ 'market.pscaCode' | translate }} &nbsp;<i class="fa fa-sort"></i>
          </th>
          <th class="centered-text" (click)="sort('color')">
            {{ 'market.color' | translate }} &nbsp;<i class="fa fa-sort"></i>
          </th>
          <th class="centered-text" (click)="sort('quantity')">
            {{ 'market.quantity' | translate }} &nbsp;<i class="fa fa-sort"></i>
          </th>
        </tr>
      </thead>
      <tbody>
        <ng-container *ngFor="let marketRow of displayedData | orderBy: key:reverse">
          <tr>
            <td>
              &nbsp;&nbsp;
              <i
                (click)="marketRowToggle(marketRow)"
                [ngClass]="marketRow.toggle ? 'fa-angle-down' : 'fa-angle-right'"
                class="fas">
              </i>
            </td>
            <td class="centered-text">{{ marketRow.partNumber }}</td>
            <td class="centered-text">{{ marketRow.pscaCode }}</td>
            <td class="centered-text">{{ marketRow.color }}</td>
            <td class="centered-text">{{ marketRow.quantity }}</td>
          </tr>
          <tr *ngIf="marketRow.toggle">
            <td colspan="5" style="padding: 0">
              <div class="div-part">
                <table class="table part-table">
                  <thead>
                    <tr>
                      <th>&nbsp;</th>
                      <th>
                        {{ 'market.partNumber' | translate }}
                      </th>
                      <th>
                        {{ 'market.color' | translate }}
                      </th>
                      <th (click)="partSort('timestamp')">
                        {{ 'market.timestamp' | translate }} &nbsp; <i class="fa fa-sort"></i>
                      </th>
                    </tr>
                  </thead>
                  <tbody>
                    <ng-container *ngFor="let part of marketRow.Parts | orderBy: partKey:partReverse">
                      <tr [ngClass]="part.select ? 'table-primary' : ''">
                        <td>
                          &nbsp;&nbsp;
                          <input
                            class="form-check-input"
                            type="checkbox"
                            value="{{ part.partId }}"
                            id="flexCheckDefault"
                            (click)="partCheck(part)"
                            [checked]="part.select" />
                          &nbsp;
                        </td>
                        <td>{{ part.partNumber }}</td>
                        <td>{{ part.colorNumber }}</td>
                        <td>{{ part.timestamp }}</td>
                      </tr>
                    </ng-container>
                  </tbody>
                </table>
              </div>
            </td>
          </tr>
        </ng-container>
      </tbody>
    </table>
    <mat-paginator
      *ngIf="!isLoading"
      #paginator
      (page)="pageChangeEvent($event)"
      [length]="totalRecords"
      [pageSize]="pageSize"
      [pageIndex]="pageIndex"
      [length]="totalRecords"
      [pageSizeOptions]="[25, 50, 100]"
      showFirstLastButtons>
    </mat-paginator>

    <div class="d-grid d-md-flex justify-content-md-end">
      <button class="btn btn-primary" (click)="deleteParts(content)" [disabled]="verifyParts() || isLoading">
        Apagar Peças
      </button>
    </div>
  </div>
</div>
