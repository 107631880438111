/* eslint-disable @typescript-eslint/no-explicit-any */
/*
 * File: \src\app\services\apptoast.service.ts
 * Project: boxpart-console
 * Created Date: 2021-10-14 12:10:15
 * Author: Jorge Herrera (jfelix@vonbraunlabs.com.br)
 * -----
 * Copyright 2021 Von Braun Labs.
 * -----
 * HISTORY:
 * Date      	By	Comments
 * ----------	---	---------------------------------------------------------
 * 2021-11-11	JH	Applied typescrypt lint check.
 */

import { Injectable, TemplateRef } from '@angular/core';

@Injectable({ providedIn: 'root' })
export class AppToastService {
  toasts: any[] = [];

  show(textOrTpl: string | TemplateRef<any>, options: any = {}) {
    this.toasts.push({ textOrTpl, ...options });
  }

  success(text: string) {
    this.show(text, { classname: 'bg-success text-light' });
  }

  error(text: string) {
    this.show(text, { classname: 'bg-danger text-light' });
  }

  remove(toast: string) {
    this.toasts = this.toasts.filter(t => t != toast);
  }
}
