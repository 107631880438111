/*
 * File: app.component.ts
 * Project: boxpart-console
 * File Created: Thursday, 14th October 2021 10:07:12 am
 * Author: Jorge Herrera (jfelix@vonbraunlabs.com.br)
 * -----
 * Copyright 2021 - Von Braun Labs.
 * -----
 * HISTORY:
 * Date      	By	Comments
 * ----------	---	---------------------------------------------------------
 * 2021-11-12	TC	pt as default language
 * 2021-10-14	JH	The main (visual) component
 * 2021-12-14	BL	Use current user role
 */

import { Component, OnInit } from '@angular/core';
import { NavigationEnd, Router } from '@angular/router';
import { environment } from 'src/environments/environment';
import { LoginService } from './services/login.service';
import { TranslateService } from '@ngx-translate/core';

import { MetadataService } from './services/metadata.service';

import { filter } from 'rxjs/operators';
import { ErrorMessageService } from '@services/error-message.service';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.css'],
})
export class AppComponent implements OnInit {
  title = 'BoxPart Console';
  version = environment.version;
  navbarOpen = false;
  isLogged = false;
  isAdmin: boolean | undefined = false;
  isLogs: boolean | undefined = false;
  isDev: boolean | undefined = false;
  roles: string[] | undefined = [];
  userName: string | undefined = '';
  loading = true;
  isDashboardPage = false;

  constructor(
    private loginService: LoginService,
    public router: Router,
    private translate: TranslateService,
    private metadataService: MetadataService,
    private errorMessageService: ErrorMessageService
  ) {
    translate.addLangs(['en', 'pt']);
    translate.setDefaultLang('pt');

    // Set app language based on browser language
    const browserLang = translate.getBrowserLang();
    translate.use(browserLang.match(/en|pt/) ? browserLang : 'pt');
    errorMessageService.setLanguage(browserLang.match(/en|pt/) ? browserLang : 'pt');
  }

  async ngOnInit(): Promise<void> {
    // This code allows to reload page data on navigation redirect.
    this.router.events.pipe(filter(event => event instanceof NavigationEnd)).subscribe(() => {
      this.isLogged = this.loginService.isLogged();
      this.roles = this.loginService.getRoles();
      this.isAdmin = this.loginService.isAdmin();
      this.isLogs = this.loginService.isLogs();
      this.isDev = this.loginService.isDev();
      this.userName = this.loginService.getUser();
      this.isDashboardPage =
        this.router.url === '/dashboard' ||
        this.router.url === '/sequencing-dashboard/deflector1' ||
        this.router.url === '/sequencing-dashboard/deflector2' ||
        this.router.url === '/sequencing-dashboard/deflector3' ||
        this.router.url === '/sequencing-dashboard/bumper1' ||
        this.router.url === '/sequencing-dashboard/bumper2' ||
        this.router.url === '/sequencing-dashboard/mudguard' ||
        this.router.url === '/logistic-panel';
    });

    if (this.loginService.isLogged()) await this.metadataService.load();
    this.loading = false;
  }

  toggleNavbar(): void {
    this.navbarOpen = !this.navbarOpen;
  }

  printer(): void {
    this.router.navigate(['/printer']);
  }

  logout(): void {
    this.loginService.logout();
    this.router.navigate(['/login']);
  }
}
