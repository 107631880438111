/*
 * File: auth.types.ts
 * Project: boxpart-console
 * File Created: Thursday, 14th October 2021 3:16:28 pm
 * Author: Jorge Herrera (jfelix@vonbraunlabs.com.br)
 * -----
 * Copyright 2021 - Von Braun Labs.
 * -----
 * HISTORY:
 * Date      	By	Comments
 * ----------	---	---------------------------------------------------------
 * 2021-10-14	JH	Authentication data structures.
 */

/** AuthUser returned by the login service. */
type AuthUser = {
  id: number;
  email: string;
  names: string;
  roles: string[];
};

/** AuthData class structure matches returned data from backend login service. */
export class AuthData {
  token?: string = '';
  authUser?: AuthUser;
  email?: string;
  login?: string;
  roles?: string[];
}
