import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { formatDate } from '@angular/common';

import { environment } from '@env';
import { LoginService } from './login.service';
import {
  boxesQuantityPerShiftDto,
  boxesStatesQuantityDto,
  expeditionDatalakeDto,
  kittingDatalakeBox,
  sequencingKittingDatalakeBox,
} from './../common/types/dashboard.types';

const API = environment.applicationApiUrl;

@Injectable({
  providedIn: 'root',
})
export class DashboardService {
  private currentlyQuantityPerPage: number;

  constructor(private http: HttpClient, private login: LoginService) {
    this.currentlyQuantityPerPage = 0;
  }

  setCurrentlyQuantityPerPage(currentlyQuantityPerPage: number): void {
    this.currentlyQuantityPerPage = currentlyQuantityPerPage;
  }

  getCurrentlyQuantityPerPage(): number {
    return this.currentlyQuantityPerPage;
  }

  /** Load the header authorization for requests
   * Use loadAuthData from login service and HttpHeaders */
  prepareRequest() {
    const auth = this.login.loadAuthData();
    const headers = new HttpHeaders().set('Authorization', `bearer ${auth.token}`);
    return headers;
  }

  /** Request a kitting set from the backend. */
  getKitting(date = new Date().toString(), range = 0): Observable<kittingDatalakeBox[]> {
    const headers = this.prepareRequest();

    date = this.transformDateFormat(date);

    return this.http.get<kittingDatalakeBox[]>(`${API}/datalake/kitting/${date}/${range}`, { headers });
  }

  /** Request a kitting set from the backend. */
  getSequencingKitting(
    type: string,
    numberRecords = 66,
    states?: number[]
  ): Observable<sequencingKittingDatalakeBox[]> {
    const headers = this.prepareRequest();

    let params = new HttpParams();
    if (states)
      params = new HttpParams({
        fromObject: {
          states: states,
        },
      });

    return this.http.get<sequencingKittingDatalakeBox[]>(`${API}/datalake/whiteboard/${type}/${numberRecords}`, {
      headers,
      params,
    });
  }

  /** Request expeditions array from the backend. */
  getExpeditions(expeditionDate = new Date()): Observable<expeditionDatalakeDto[]> {
    const headers = this.prepareRequest();

    expeditionDate.setHours(23, 60, 0);

    const body = {
      expeditionDate,
    };

    return this.http.post<expeditionDatalakeDto[]>(`${API}/datalake/expeditions`, body, {
      headers,
    });
  }

  /** Request boxes states of a month format yyyyMM  */
  getBoxesStatesQuantity(yearMonth: string): Observable<boxesStatesQuantityDto> {
    const headers = this.prepareRequest();

    return this.http.get<boxesStatesQuantityDto>(`${API}/datalake/box/${yearMonth}`, { headers });
  }

  /** Request boxes per shift quantity by date (yyyyMMdd)  */
  getBoxesQuantityPerShift(boxStateDate: string, sequenceType: string): Observable<boxesQuantityPerShiftDto> {
    const headers = this.prepareRequest();

    return this.http.get<boxesQuantityPerShiftDto>(`${API}/datalake/boxes/${boxStateDate}/${sequenceType}`, { headers });
  }

  /** 2021-10-17T00:00:00 to 20211017 (yyyyMMdd) -> API uses this format. */
  transformDateFormat(date: string) {
    try {
      return formatDate(Date.parse(date), 'yyyyMMdd', navigator.language);
    } catch {
      return date;
    }
  }
}
