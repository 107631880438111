import { SequencesService } from 'src/app/services/sequences.service';
import { Router } from '@angular/router';
import { Component, OnInit } from '@angular/core';
import { DeclarationPack, Packs } from './../../common/types/sequences.types';
import { TranslateService } from '@ngx-translate/core';
import { AppToastService } from '@services/apptoast.service';

@Component({
  selector: 'app-declaration-print',
  templateUrl: './declaration-print.component.html',
  styleUrls: ['./declaration-print.component.scss'],
})
export class DeclarationPrintComponent implements OnInit {
  public packs: Packs[] = [];
  public declarationPacksResponse: DeclarationPack[] = [];
  public declarationPacks: DeclarationPack[] = [];

  constructor(
    private router: Router,
    private sequencesService: SequencesService,
    private translate: TranslateService,
    private toast: AppToastService
  ) {
    const nav = this.router.getCurrentNavigation();

    if (nav?.extras.state?.packs) {
      this.packs = nav.extras.state.packs;
    }
  }

  async ngOnInit(): Promise<void> {
    this.declarationPacksResponse = await this.sequencesService.getDeclarationInfo(this.packs).toPromise();

    const emptyPacks: string[] = [];

    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    this.declarationPacksResponse.map((pack, _index) => {
      if (pack.parts.length === 0) {
        emptyPacks.push(pack.refNumber);
      } else {
        this.declarationPacks.push(pack);
      }
    });

    setTimeout(() => {
      if (this.declarationPacks.length > 0) window.print();
      this.router.navigate([{ outlets: { print: null } }]);
      if (emptyPacks.length > 0)
        this.toast.error(`Algumas embalagens ainda não estão fechadas ou não possuem todas declarações: Ref. ${emptyPacks.join(', Ref. ')}.`);
    }, 100);
  }
}
