/*
 * File: login.component.ts
 * Project: boxpart-console
 * File Created: Thursday, 14th October 2021 10:16:01 am
 * Author: Jorge Herrera (jfelix@vonbraunlabs.com.br)
 * -----
 * Copyright 2021 - VB Labs
 * -----
 * HISTORY:
 * Date      	By	Comments
 * ----------	---	---------------------------------------------------------
 * 2021-10-20	TC	File path adjustment (components folder)
 * 2021-10-14	JH  Login authentication form.
 * 2021-11-10	JH	Added I18N support.
 * 2021-11-11	JH	Lint enforced.
 */

import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';

import { TranslateService } from '@ngx-translate/core';
import { ErrorMessageService } from '@services/error-message.service';
import { AppToastService } from '../../services/apptoast.service';
import { LoginService } from '../../services/login.service';
import { MetadataService } from './../../services/metadata.service';

/** Implements the '/login' form component. */
@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.css'],
})
export class LoginComponent implements OnInit {
  loginForm: FormGroup;
  failed: boolean;
  requesting: boolean;

  /** Default class constructor. */
  constructor(
    private fb: FormBuilder,
    private service: LoginService,
    private router: Router,
    private toast: AppToastService,
    private translate: TranslateService,
    private metadataService: MetadataService,
    private errorMessageService: ErrorMessageService
  ) {
    this.failed = false;
    this.requesting = false;
    this.loginForm = new FormGroup({});
  }

  /** Creates the form object. */
  ngOnInit(): void {
    this.loginForm = this.fb.group({
      login: ['', Validators.required],
      password: ['', Validators.required],
    });
  }

  /** Called on login form submit. */
  login() {
    if (false === this.loginForm?.invalid) {
      this.failed = false;
      this.requesting = true;

      // Call the backend authentication service.
      this.service.login(this.loginForm.value['login'], this.loginForm.value['password']).subscribe(
        async authData => {
          if (authData.roles?.includes('admin') || authData.roles?.includes('dev') || authData.roles?.includes('console')) {
            this.service.saveAuthData(authData);
            await this.metadataService.load().then(() => {
              this.router.navigate(['/home']);
              this.toast.success(this.translate.instant('login.success'));
            });
          } else {
            this.toast.error(this.translate.instant('login.noPermission'));
            this.finalize(true);
          }
        },
        response => {
          const message = response.error.message ? response.error : response.message;
          this.toast.error(this.errorMessageService.translate(message.code));

          this.finalize(true);
        }
      );
    }
  }

  /** Called on login operation ending. */
  private finalize(failed: boolean) {
    this.failed = failed;
    this.requesting = false;
    this.loginForm?.controls.password.reset();
  }
}
