<!--
File: app.component.html
Project: boxpart-console
File Created: Thursday, 14th October 2021 10:07:12 am
Author: Jorge Herrera (jfelix@vonbraunlabs.com.br)
-----
Copyright 2021 - Von Braun Labs.
-----
HISTORY:
Date      	By	Comments
----------	---	---------------------------------------------------------
2022-05-31	TC	Enable bumper and deflector dashboards
2021-10-25	TC	Translate language (english to portuguese)
2021-10-20	TC	New navbar with bootstrap toggler
2021-10-20	TC	Single Page Applications (SPA) behavior adjustment with routerLink
2021-10-15	TC	Added container-fluid div in navbar
2021-10-14	JH	Root component html layout.
2021-11-10	JH	Added I18N support.
2021-12-14	BL	Added link to log management.
-->

<div class="loading" *ngIf="loading">
  <div class="spinner-border text-primary" style="width: 4rem; height: 4rem" role="status">
    <span class="visually-hidden">Loading...</span>
  </div>
</div>

<div *ngIf="!loading">
  <div class="nav-container" [class.floating-navbar]="isDashboardPage">
    <nav class="navbar navbar-expand-xl navbar-dark bg-primary shadow-sm" [class.hidden-navbar]="isDashboardPage">
      <div class="container-fluid">
        <a class="navbar-brand" routerLink="/home" routerLinkActive="active">
          <img src="../assets/images/boxpart-logo.svg" class="boxpart-logo" />
          {{ title }}
        </a>
        <button
          class="navbar-toggler"
          type="button"
          data-bs-toggle="collapse"
          data-bs-target="#navbarSupportedContent"
          aria-controls="navbarSupportedContent"
          aria-expanded="false"
          aria-label="Toggle navigation">
          <span class="navbar-toggler-icon"></span>
        </button>
        <div class="collapse navbar-collapse" id="navbarSupportedContent">
          <ul class="navbar-nav me-auto mb-2 mb-md-1 flex-align-center">
            <li *ngIf="isLogged" class="nav-item">
              <a class="nav-link" routerLink="/files" routerLinkActive="active">{{ 'navbar.files' | translate }}</a>
            </li>
            <li *ngIf="isLogged" class="nav-item">
              <a class="nav-link" routerLink="/sequences" routerLinkActive="active">{{
                'navbar.sequences' | translate
              }}</a>
            </li>
            <li *ngIf="isLogged" class="nav-item">
              <a class="nav-link" routerLink="/expedition-list" routerLinkActive="active">{{
                'navbar.expedition' | translate
              }}</a>
            </li>
            <li *ngIf="isLogged" class="nav-item body" ngbDropdown>
              <a
                ngbDropdownToggle
                class="nav-link dropdown-toggle"
                type="button"
                id="dropdownToggleButton"
                aria-expanded="false">
                {{ 'navbar.dashboard' | translate }}
              </a>
              <ul ngbDropdownMenu aria-labelledby="dropdownMenuButton">
                <li>
                  <a ngbDropdownItem routerLink="/dashboard" routerLinkActive="active">
                    {{ 'dashboards.general' | translate }}
                  </a>
                </li>
                <li>
                  <a ngbDropdownItem routerLink="/sequencing-dashboard/deflector1" routerLinkActive="active">
                    {{ 'dashboards.deflector' | translate }} 1
                  </a>
                </li>
                <li>
                  <a ngbDropdownItem routerLink="/sequencing-dashboard/deflector2" routerLinkActive="active">
                    {{ 'dashboards.deflector' | translate }} 2
                  </a>
                </li>
                <li>
                  <a ngbDropdownItem routerLink="/sequencing-dashboard/deflector3" routerLinkActive="active">
                    {{ 'dashboards.deflector' | translate }} 3
                  </a>
                </li>
                <li>
                  <a ngbDropdownItem routerLink="/sequencing-dashboard/bumper1" routerLinkActive="active">
                    {{ 'dashboards.bumper' | translate }} 1
                  </a>
                </li>
                <li>
                  <a ngbDropdownItem routerLink="/sequencing-dashboard/bumper2" routerLinkActive="active">
                    {{ 'dashboards.bumper' | translate }} 2
                  </a>
                </li>
                <li>
                  <a ngbDropdownItem routerLink="/sequencing-dashboard/mudguard" routerLinkActive="active">
                    {{ 'dashboards.mudguard' | translate }}
                  </a>
                </li>
                <li *ngIf="isLogged">
                  <a ngbDropdownItem routerLink="/logistic-panel" routerLinkActive="active">
                    {{ 'navbar.logistic-panel' | translate }}
                  </a>
                </li>
              </ul>
            </li>
            <li *ngIf="isLogged" class="nav-item body" ngbDropdown>
              <a
                ngbDropdownToggle
                class="nav-link dropdown-toggle"
                type="button"
                id="dropdownToggleButton"
                aria-expanded="false">
                {{ 'navbar.logs' | translate }}
              </a>
              <ul ngbDropdownMenu aria-labelledby="dropdownMenuButton">
                <li *ngIf="isLogged">
                  <a ngbDropdownItem routerLink="/event-logs" routerLinkActive="active">
                    {{ 'navbar.eventLogs' | translate }}
                  </a>
                </li>
                <li *ngIf="isLogged">
                  <a ngbDropdownItem routerLink="/flat-logs" routerLinkActive="active">
                    {{ 'navbar.flatLogs' | translate }}
                  </a>
                </li>
              </ul>
            </li>
            <li *ngIf="isLogged" class="nav-item">
              <a class="nav-link" routerLink="/market" routerLinkActive="active">
                {{ 'navbar.market' | translate }}
              </a>
            </li>
            <li *ngIf="isLogged" class="nav-item">
              <a class="nav-link" routerLink="/generate-report" routerLinkActive="active">
                {{ 'navbar.generate-report' | translate }}
              </a>
            </li>
            <li *ngIf="isLogged && (isAdmin || isDev)" class="nav-item">
              <a class="nav-link" routerLink="/configuration" routerLinkActive="active">{{
                'navbar.configuration' | translate
              }}</a>
            </li>
          </ul>
          <ng-container *ngIf="!isLogged; else showLogout">
            <button class="btn btn-primary" routerLink="/login" routerLinkActive="active">
              {{ 'navbar.enter' | translate }}
            </button>
          </ng-container>
          <ng-template #showLogout>
            <span class="welcome-user">{{ 'navbar.welcome' | translate }}, {{ userName }}</span>
            <button class="btn btn-danger" (click)="logout()">
              {{ 'navbar.exit' | translate }}
            </button>
          </ng-template>
        </div>
      </div>
    </nav>
  </div>
  <div class="bottom-area" *ngIf="!isDashboardPage">{{ 'version' | translate }}: {{ version }}</div>
</div>

<!-- The router rendering -->
<router-outlet *ngIf="!loading"></router-outlet>
<!-- Print layout router -->
<router-outlet name="print"></router-outlet>

<!-- Toast application support -->
<app-toasts aria-live="polite" aria-atomic="true"></app-toasts>
