import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';

import { environment } from '@env';
import { LoginService } from './login.service';

const API = environment.applicationApiUrl;

@Injectable({
  providedIn: 'root',
})
export class LogisticService {
  constructor(private http: HttpClient, private login: LoginService) {}

  // Load the header authorization for requests
  // Use loadAuthData from login service and HttpHeaders
  prepareRequest() {
    const auth = this.login.loadAuthData();
    const headers = new HttpHeaders().set(
      'Authorization',
      `bearer ${auth.token}`
    );
    return headers;
  }

  /** Request a sequence set from the backend. */
  getLogisticData(type, date) {
    const headers = this.prepareRequest();
    return this.http.get<
      {
        partNumber: string;
        allCount: number;
        scanedCount: number;
      }[]
    >(`${API}/datalake/parts/${type}/${date}`, { headers });
  }
}
