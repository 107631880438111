import { MetadataService } from './../../services/metadata.service';
import { Component, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { DataTableDirective } from 'angular-datatables';
import { TranslateService } from '@ngx-translate/core';
import { LogsService } from './../../services/logs.service';
import { LogEntry } from '../../common/types/logs.types';

import { NgbModal, ModalDismissReasons } from '@ng-bootstrap/ng-bootstrap';

import { Subject } from 'rxjs';
import { formatDate } from '@angular/common';
//aa

@Component({
  selector: 'app-list-logs',
  templateUrl: './list-logs.component.html',
  styleUrls: ['./list-logs.component.css'],
})
export class ListLogsComponent implements OnInit, OnDestroy {
  @ViewChild(DataTableDirective, { static: false })
  dtElement: DataTableDirective | undefined;

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  logMetadata: any;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  errorsMetadata: any;

  dtOptions: DataTables.Settings = {};
  logEntries: LogEntry[] = [];
  originalData: LogEntry[] = [];
  modalData: LogEntry = {} as LogEntry;

  // We use this trigger because fetching the list of persons can be quite long,
  // thus we ensure the data is fetched before rendering
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  dtTrigger: Subject<any> = new Subject<any>();
  loading = false;

  selectedGravity = '';
  closeResult = '';

  constructor(
    private logsService: LogsService,
    private translate: TranslateService,
    private metadataService: MetadataService,
    private modalService: NgbModal
  ) {}

  ngOnInit(): void {
    this.loading = true;
    this.logMetadata = this.metadataService.getLog();
    this.errorsMetadata = this.metadataService.getErrors();

    this.dtOptions = {
      pagingType: 'full_numbers',
      pageLength: 10,
      order: [],
      columnDefs: [{ type: 'date', targets: 0 }],
      language: this.translate.instant('logs'),
    };

    this.logsService.retrieveLogEntries().subscribe(resp => {
      resp.map(log => {
        const logEntry: LogEntry = {
          ...log,
          detailedData: this.transformDetailedData(log),
          gravity: this.logMetadata.eventDetailDict[log.eventDetailNumber].gravity,
          timestamp: this.transformDate(log.timestamp + 'Z'),
          contextTag: this.transformContext(log.contextTag),
          data: this.transformData(log),
        };

        if (this.translate.getBrowserLang() === 'en') {
          logEntry.eventClassNumber = this.logMetadata.eventClassDict[log.eventClassNumber].nameEN;
          logEntry.eventDetailNumber = this.logMetadata.eventDetailDict[log.eventDetailNumber].nameEN;
        } else {
          logEntry.eventClassNumber = this.logMetadata.eventClassDict[log.eventClassNumber].namePT;
          logEntry.eventDetailNumber = this.logMetadata.eventDetailDict[log.eventDetailNumber].namePT;
        }

        this.originalData.push(logEntry);
      });
      this.logEntries = this.originalData;

      // Calling the DT trigger to manually render the table
      this.dtTrigger.next();
      this.loading = false;
    });
  }

  ngOnDestroy(): void {
    // Do not forget to unsubscribe the event
    this.dtTrigger.unsubscribe();
  }

  onGravityFilterChange() {
    this.loading = true;
    this.dtElement?.dtInstance.then((dtInstance: DataTables.Api) => {
      // Destroy the table first
      dtInstance.destroy();

      this.logEntries = this.originalData;
      if (this.selectedGravity)
        this.logEntries = this.logEntries.filter(item => item.gravity === Number(this.selectedGravity));
      this.loading = false;

      // Call the dtTrigger to rerender again
      this.dtTrigger.next();
    });
    //this.dtTrigger.next();
  }

  transformContext(context: string) {
    if (!context) context = 'noContext';
    return this.translate.instant(`logs.data.context.${context}`);
  }

  transformDetailedData(log: LogEntry): string {
    let detailedData = '';
    if (log.contextTag === 'internal_file') {
      try {
        const parsedData = JSON.parse(log.data);

        if (!parsedData.errorProcessing && !parsedData.errorCollection) return detailedData;
        if (parsedData.errorProcessing) detailedData += parsedData.errorProcessing + '\n\n';
        if (parsedData.errorCollection) {
          parsedData.errorCollection.map(error => {
            detailedData += `Na linha <strong>${error.nLine}</strong>: ${
              this.errorsMetadata[error.errorType].messagePT
            }. Valor preenchido: '${error.sError}.'\n`;
          });
        }
      } catch (error) {
        detailedData = '';
      }
    } else if (log.eventDetailNumber === 5001) {
      try {
        const parsedData = JSON.parse(log.data);

        if (parsedData.size) {
          detailedData += 'Quantidade de peças: ' + parsedData.size;
        } else {
          for (let i = 0; i < parsedData.parts.length; i++) {
            detailedData +=
              'ItemBruto: ' + parsedData.parts[i].grossItemCode + '; Pop-Id: ' + parsedData.parts[i].popId + '\n';
          }
        }
      } catch (error) {
        detailedData = '';
        console.log(error);
      }
    }
    return detailedData;
  }

  transformData(log: LogEntry) {
    // if have any data
    if (log.data) {
      const newData: string[] = [];
      if (log.data[0] === '{') {
        try {
          const data = JSON.parse(log.data);

          // Concatenates possible values in array
          // Box
          if (Object.keys(data).includes('boxIdentifier')) {
            newData.push(this.getBoxLogData(data));
          }

          // Part
          if (Object.keys(data).includes('itemCode')) {
            newData.push(this.getPartLogData(data));
          }

          // Expedition
          if (Object.keys(data).includes('transportType')) {
            newData.push(this.getExpeditionLogData(data));
          }

          if (Object.keys(data).includes('labelAIdentifier')) {
            newData.push(this.getLabelALogData(data));
          }

          // Navision File
          if (Object.keys(data).includes('Filename')) {
            newData.push(this.getNavisionFileLogData(data));
          }

          if (Object.keys(data).includes('flatFileIdentifier')) {
            newData.push(this.getNavisionFileLogData(data.flatFileIdentifier));
          }

          if (log.code < 100 || log.code >= 1000) {
            if (log.code === 3015) {
              newData.push(this.getPartData(data));
            }
            if (this.translate.getBrowserLang() === 'en') newData.push(this.errorsMetadata[log.code].message);
            else newData.push(this.errorsMetadata[log.code].messagePT);
          }

          if (data.result) {
            newData.push(this.translate.instant('logs.data.' + data.result));
          }

          // if have any values, join and return
          if (newData.length) return newData.join('\n');
        } catch (error) {
          console.log(error);
        }
      }
    }
    return this.translate.instant('logs.data.noData');
  }

  transformDate(date: string) {
    return new Date(date).toLocaleString('default', {
      year: 'numeric',
      month: 'numeric',
      day: 'numeric',
      hour: 'numeric',
      minute: 'numeric',
    });
  }

  open(content, LogEntry: LogEntry) {
    this.modalData = LogEntry;
    this.modalService.open(content, { centered: true, size: 'lg' }).result.then(
      result => {
        this.closeResult = `Closed with: ${result}`;
      },
      reason => {
        this.closeResult = `Dismissed ${this.getDismissReason(reason)}`;
      }
    );
  }

  private getDismissReason(reason: unknown): string {
    if (reason === ModalDismissReasons.ESC) {
      return 'by pressing ESC';
    } else if (reason === ModalDismissReasons.BACKDROP_CLICK) {
      return 'by clicking on a backdrop';
    } else {
      return `with: ${reason}`;
    }
  }

  private getExpeditionLogData(data: Record<string, unknown>): string {
    return (
      '<strong>' +
      this.translate.instant('logs.data.expedition') +
      ':</strong> ' +
      data.transportType +
      ', ' +
      this.transformDate(data.dateTime as string)
    );
  }

  private getBoxLogData(data: Record<string, unknown>): string {
    return (
      '<strong>' +
      this.translate.instant('logs.data.assemblyPack') +
      ':</strong> ' +
      this.translate.instant('logs.data.refNumber') +
      ' ' +
      (data.boxIdentifier as Record<string, unknown>).refNumber +
      ', ' +
      this.translate.instant('logs.data.sequenceType') +
      ' ' +
      (data.boxIdentifier as Record<string, unknown>).sequenceType +
      ', ' +
      this.translate.instant('logs.data.supplier') +
      ' ' +
      (data.boxIdentifier as Record<string, unknown>).supplier +
      ', ' +
      formatDate(
        Date.parse((data.boxIdentifier as Record<string, string>).sequenceDate),
        'shortDate',
        navigator.language
      )
    );
  }

  private getPartLogData(data: Record<string, unknown>): string {
    return (
      '<strong>' +
      this.translate.instant('logs.data.assemblyDetail') +
      ':</strong> ' +
      'POPID ' +
      data.popId +
      ', ' +
      'PN ' +
      data.itemCode
    );
  }

  private getNavisionFileLogData(data: Record<string, unknown>): string {
    let string = '<strong>' + this.translate.instant('logs.data.navisionFile') + ': </strong>' + data.Filename;

    if (data.defaultPath) {
      string += '</br>' + this.translate.instant('flatLogs.defaultPath');
    }

    return string;
  }

  private getPartData(data: Record<string, unknown>): string {
    return (
      '<strong>' +
      this.translate.instant('common.part') +
      ': </strong>' +
      this.translate.instant('common.partNumber') +
      ': ' +
      data.partNumber +
      ', ' +
      this.translate.instant('common.colorRef') +
      ': ' +
      data.colorNumber
    );
  }

  private getLabelALogData(data: Record<string, unknown>) {
    return (
      '<strong>' +
      this.translate.instant('common.part') +
      ': </strong>' +
      this.translate.instant('common.partNumber') +
      ': ' +
      (data.labelAIdentifier as Record<string, unknown>).partNumber +
      ', ' +
      this.translate.instant('common.colorRef') +
      ': ' +
      (data.labelAIdentifier as Record<string, unknown>).colorNumber
    );
  }
}
