/* eslint-disable @typescript-eslint/no-explicit-any */
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';

import { environment } from '@env';
import { LoginService } from './login.service';

const API = environment.applicationApiUrl;

@Injectable({
  providedIn: 'root',
})
export class MetadataService {
  public log: any;
  public box: any;
  public errors: any;
  public colors: any;

  constructor(private http: HttpClient, private login: LoginService) {}

  getLog() {
    return this.log;
  }

  getBox() {
    return this.box;
  }

  getErrors() {
    return this.errors;
  }

  getColors() {
    return this.colors;
  }

  // Load the header authorization for requests
  // Use loadAuthData from login service and HttpHeaders
  prepareRequest() {
    const auth = this.login.loadAuthData();
    const headers = new HttpHeaders().set(
      'Authorization',
      `bearer ${auth.token}`
    );
    return headers;
  }

  /** Request the metadata logs dictionary. */
  async load() {
    await this.logMetadata()
      .then(data => {
        this.log = data;
      })
      .catch(error => console.log('Log metadata cannot be loaded.', error));

    await this.boxMetadata()
      .then(data => {
        this.box = data;
      })
      .catch(error => console.log('Box metadata cannot be loaded.', error));

    await this.errorMetadata()
      .then(data => {
        this.errors = data;
      })
      .catch(error => console.log('Errors metadata cannot be loaded.', error));

    await this.colorMetadata()
      .then(data => {
        this.colors = data;
      })
      .catch(error => console.log('Colors metadata cannot be loaded.', error));
  }

  logMetadata() {
    const headers = this.prepareRequest();
    return this.http.get<any>(`${API}/metadata/log`, { headers }).toPromise();
  }

  boxMetadata() {
    const headers = this.prepareRequest();
    return this.http.get<any>(`${API}/metadata/box`, { headers }).toPromise();
  }

  errorMetadata() {
    const headers = this.prepareRequest();
    return this.http
      .get<any>(`${API}/metadata/errors`, { headers })
      .toPromise();
  }

  colorMetadata() {
    const headers = this.prepareRequest();
    return this.http.get<any>(`${API}/metadata/color`, { headers }).toPromise();
  }
}
