<p>printer works!</p>

<table>
  <thead>
    <tr>
      <th>UID</th>
      <th>Printer</th>
      <th>Device Type</th>
      <th>Connection</th>
      <th>Action</th>
    </tr>
  </thead>
  <tbody>
    <tr *ngFor="let printer of printers">
      <td>{{ printer.uid }}</td>
      <td>{{ printer.name }}</td>
      <td>{{ printer.deviceType }}</td>
      <td>{{ printer.connection }}</td>
      <td><button (click)="print(printer.uid)">Print</button></td>
    </tr>
  </tbody>
</table>
