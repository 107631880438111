<div class="declarationPage" *ngFor="let declarationPack of declarationPacks">
  <div class="pack">
    <div>
      <b>Data de montagem</b>
      <p>{{ declarationPack.sequenceDate | date: 'shortDate' }}</p>
    </div>
    <div>
      <b>Ref. montagem</b>
      <p>{{ declarationPack.refNumber }}</p>
    </div>
    <div>
      <b>Tipo</b>
      <p>{{ 'files.' + declarationPack.sequenceType | translate }}</p>
    </div>
    <div>
      <b>Fornecimento</b>
      <p>{{ declarationPack.supplier }}</p>
    </div>
  </div>

  <div class="partsContainer">
    <div class="part">
      <b>Código PSCA</b>
      <b>Declaração de produção</b>
    </div>

    <div class="part" *ngFor="let part of declarationPack.parts">
      <p>{{ part.pscaCode }}</p>
      <ngx-barcode
        [bc-value]="part.fiche"
        [bc-display-value]="true"
        [bc-height]="33"
        [bc-width]="2"
        [bc-font-size]="10"
        [bc-background]="'transparent'"
        [bc-margin]="0"></ngx-barcode>
    </div>
  </div>
</div>
