<div class="content">
  <mat-stepper orientation="vertical" linear #stepper>
    <mat-step [stepControl]="firstFormGroup" [editable]="isEditable">
      <form [formGroup]="firstFormGroup" class="header-form">
        <ng-template matStepLabel>Preencha o cabeçalho</ng-template>

        <label id="tranport-type-label">{{ 'expedition.transportType' | translate }}</label>
        <mat-radio-group
          formControlName="transportType"
          aria-labelledby="tranport-type-label"
          class="radio-group"
          color="primary">
          <mat-radio-button class="radio-button" value="Milk Run">Milk Run</mat-radio-button>
          <mat-radio-button class="radio-button" value="Frete Extra">Frete Extra</mat-radio-button>
        </mat-radio-group>

        <div>
          <mat-form-field appearance="fill">
            <mat-label>{{ 'expedition.collectionDate' | translate }}</mat-label>
            <input
              matInput
              type="text"
              formControlName="collectDate"
              ngbDatepicker
              #d="ngbDatepicker"
              (click)="d.open()"
              placeholder="Data"
              container="body"
              required />
          </mat-form-field>
        </div>

        <div>
          <mat-form-field appearance="fill">
            <mat-label>{{ 'expedition.collectionTime' | translate }}</mat-label>
            <input matInput type="time" container="body" formControlName="collectTime" placeholder="Horário" required />
          </mat-form-field>
        </div>

        <label id="vehicle-type-label">{{ 'expedition.vehicleType' | translate }}</label>
        <mat-radio-group
          formControlName="vehicleType"
          aria-labelledby="vehicle-type-label"
          class="radio-group"
          color="primary">
          <mat-radio-button class="radio-button" value="Caminhonete">Caminhonete</mat-radio-button>
          <mat-radio-button class="radio-button" value="3/4">3/4</mat-radio-button>
          <mat-radio-button class="radio-button" value="Toco">Toco</mat-radio-button>
          <mat-radio-button class="radio-button" value="Truck">Truck</mat-radio-button>
          <mat-radio-button class="radio-button" value="Carreta">Carreta</mat-radio-button>
        </mat-radio-group>

        <div class="d-grid d-md-flex justify-content-md-end">
          <button mat-flat-button color="primary" matStepperNext>
            {{ 'expedition.next' | translate }}
          </button>
        </div>
      </form>
    </mat-step>

    <mat-step [completed]="!verifyPrintList() && !loading && !printDisabled" [editable]="isEditable" #step>
      <ng-template matStepLabel>{{ 'expedition.choosePackaging' | translate }}</ng-template>
      <form class="d-grid searchArea">
        <!-- Search area -->
        <div class="d-flex">
          <!-- Select -->
          <div class="me-2 col-sm-2">
            <select class="form-select" aria-label="Select" [(ngModel)]="selectSearchType" name="selectSearchType">
              <option value="popId" selected>
                {{ 'sequences.popId' | translate }}
              </option>
              <option value="cuCode">{{ 'sequences.cu' | translate }}</option>
              <option value="description">
                {{ 'sequences.description' | translate }}
              </option>
              <!-- <option value="item">{{'sequences.item' | translate}}</option> -->
              <option value="grossItemCode">
                {{ 'sequences.grossItem' | translate }}
              </option>
            </select>
          </div>
          <!-- Input -->
          <input
            class="form-control me-2"
            type="text"
            name="searchInput"
            [(ngModel)]="searchInput"
            placeholder="{{ 'sequences.search' | translate }}"
            aria-label="Search"
            autocomplete="off" />
          <!-- search button -->
          <button type="button" class="btn btn-primary" (click)="onSearch()">
            <i class="fas fa-search"></i>
          </button>
        </div>

        <!-- Filter area -->
        <div *ngIf="filtering" class="filter-area">
          <div class="d-grid gap-3">
            <div class="row gy-2 gx-3 align-items-center">
              <!-- Start date input -->
              <div class="col-3">
                <input
                  type="date"
                  class="form-control"
                  placeholder="Data inicial"
                  name="startDateInput"
                  [(ngModel)]="startDateInput" />
              </div>
              {{ 'sequences.filterTo' | translate }}
              <!-- End date input -->
              <div class="col-3">
                <input
                  type="date"
                  class="form-control"
                  placeholder="Data final"
                  name="endDateInput"
                  [(ngModel)]="endDateInput"
                  [min]="startDateInput"
                  [max]="maxDate(startDateInput)" />
              </div>
              <!-- Radio button for types: all, bumper and deflector -->
              <div class="btn-group col-3 mx-auto" role="group">
                <input
                  type="radio"
                  class="btn-check"
                  id="btnradio1"
                  autocomplete="off"
                  name="sequenceType"
                  [value]="'all'"
                  [(ngModel)]="sequenceType" />
                <label class="btn btn-outline-primary" for="btnradio1">{{ 'sequences.filterAll' | translate }}</label>
                <input
                  type="radio"
                  class="btn-check"
                  id="btnradio2"
                  autocomplete="off"
                  name="sequenceType"
                  [value]="'bumper'"
                  [(ngModel)]="sequenceType" />
                <label class="btn btn-outline-primary" for="btnradio2">{{
                  'sequences.filterBumper' | translate
                }}</label>
                <input
                  type="radio"
                  class="btn-check"
                  id="btnradio3"
                  autocomplete="off"
                  name="sequenceType"
                  [value]="'deflector'"
                  [(ngModel)]="sequenceType" />
                <label class="btn btn-outline-primary" for="btnradio3">{{
                  'sequences.filterDeflector' | translate
                }}</label>
                <input
                  type="radio"
                  class="btn-check"
                  id="btnradio4"
                  autocomplete="off"
                  name="sequenceType"
                  [value]="'mudguard'"
                  [(ngModel)]="sequenceType" />
                <label class="btn btn-outline-primary" for="btnradio4">{{
                  'sequences.filterMudguard' | translate
                }}</label>
              </div>
              <div class="col-2">
                <button class="btn btn-primary" (click)="clearFilters()">
                  {{ 'sequences.clearFilters' | translate }}
                </button>
              </div>
            </div>
          </div>
        </div>
      </form>

      <!-- Sequences loading -->
      <div *ngIf="loading" class="d-flex justify-content-center" style="margin: 10% auto">
        <div class="spinner-border text-primary" role="status">
          <span class="sr-only">Loading...</span>
        </div>
      </div>

      <!-- Sequences result is empty -->
      <div *ngIf="!loading && emptyResult" class="d-flex justify-content-center" style="margin: 10% auto">
        <p>{{ 'sequences.emptyResult' | translate }}</p>
      </div>

      <table *ngIf="!loading && !emptyResult" class="table sequences-table">
        <thead>
          <tr>
            <th>
              <input
                class="form-check-input"
                type="checkbox"
                id="checkboxNoLabel"
                (click)="selectAll()"
                [checked]="selectAllCheck"
                [indeterminate]="indeterminate" />
            </th>
            <th (click)="sort('date')">{{ 'sequences.date' | translate }} &nbsp;<i class="fa fa-sort"></i></th>
            <th (click)="sort('sequenceType')">{{ 'sequences.type' | translate }} &nbsp;<i class="fa fa-sort"></i></th>
            <th (click)="sort('packs')">{{ 'sequences.boxes' | translate }} &nbsp;<i class="fa fa-sort"></i></th>
          </tr>
        </thead>
        <tbody>
          <ng-container *ngFor="let sequence of sequences | orderBy: key:reverse">
            <tr [ngClass]="sequence.select ? 'table-primary' : ''">
              <td>
                &nbsp;
                <input
                  class="form-check-input"
                  type="checkbox"
                  value="{{ sequence.id }}"
                  id="flexCheckDefault"
                  (click)="check(sequence)"
                  [checked]="sequence.select"
                  [disabled]="sequence.loadingPacks" />
                &nbsp;&nbsp;
                <i
                  (click)="sequenceToggle(sequence)"
                  [ngClass]="sequence.toggle ? 'fa-angle-down' : 'fa-angle-right'"
                  class="fas">
                </i>
              </td>
              <td>{{ sequence.date | date: 'shortDate' }}</td>
              <td>{{ sequence.type }}</td>
              <td>{{ sequence.packs }}</td>
            </tr>
            <tr *ngIf="sequence.toggle">
              <td colspan="5" style="padding: 0">
                <div class="div-pack">
                  <div *ngIf="sequence.loadingPacks" class="d-flex justify-content-center" style="margin: 10% auto">
                    <div class="spinner-border text-primary" role="status">
                      <span class="sr-only">Loading...</span>
                    </div>
                  </div>

                  <table *ngIf="!sequence.loadingPacks" class="table pack-table">
                    <thead>
                      <tr>
                        <th>&nbsp;</th>
                        <th (click)="packSort('refNumber')">
                          {{ 'sequences.assemblyRef' | translate }} &nbsp;<i class="fa fa-sort"></i>
                        </th>
                        <th (click)="packSort('dispatchNumber')">
                          {{ 'sequences.boxNumber' | translate }} &nbsp;<i class="fa fa-sort"></i>
                        </th>
                        <th (click)="packSort('supplier')">
                          {{ 'sequences.supplier' | translate }} &nbsp;<i class="fa fa-sort"></i>
                        </th>
                        <th (click)="packSort('description')">
                          {{ 'sequences.description' | translate }} &nbsp;<i class="fa fa-sort"></i>
                        </th>
                      </tr>
                    </thead>
                    <tbody>
                      <ng-container *ngFor="let pack of sequence.Packs | orderBy: packKey:packReverse">
                        <tr [ngClass]="pack.select ? 'table-primary' : ''">
                          <td>
                            &nbsp;&nbsp;
                            <input
                              class="form-check-input"
                              type="checkbox"
                              value="{{ pack.id }}"
                              id="flexCheckDefault"
                              (click)="packCheck(sequence, pack)"
                              [checked]="pack.select"
                              [disabled]="pack.loadingParts" />
                            &nbsp;
                            <i
                              (click)="packToggle(pack)"
                              [ngClass]="pack.toggle ? 'fa-angle-down' : 'fa-angle-right'"
                              class="fas">
                            </i>
                            &nbsp;
                          </td>
                          <td>{{ pack.refNumber }}</td>
                          <td>{{ pack.dispatchNumber }}</td>
                          <td>{{ pack.supplier }}</td>
                          <td>{{ pack.description }}</td>
                        </tr>
                        <tr *ngIf="pack.toggle">
                          <td colspan="7" style="padding: 0">
                            <div class="div-parts">
                              <div
                                *ngIf="pack.loadingParts"
                                class="d-flex justify-content-center"
                                style="margin: 10% auto">
                                <div class="spinner-border text-primary" role="status">
                                  <span class="sr-only">Loading...</span>
                                </div>
                              </div>

                              <table *ngIf="!pack.loadingParts" class="table part-table">
                                <thead>
                                  <tr>
                                    <th (click)="partSort('itemCode')">
                                      {{ 'sequences.item' | translate }}
                                      &nbsp;<i class="fa fa-sort"></i>
                                    </th>
                                    <th (click)="partSort('cuCode')">
                                      {{ 'sequences.cu' | translate }} &nbsp;<i class="fa fa-sort"></i>
                                    </th>
                                    <th (click)="partSort('grossItemCode')">
                                      {{ 'sequences.grossItem' | translate }}
                                      &nbsp;<i class="fa fa-sort"></i>
                                    </th>
                                    <th (click)="partSort('color')">
                                      {{ 'sequences.color' | translate }}
                                      &nbsp;<i class="fa fa-sort"></i>
                                    </th>
                                    <th (click)="partSort('colorCode')">
                                      {{ 'sequences.colorCode' | translate }}
                                      &nbsp;<i class="fa fa-sort"></i>
                                    </th>
                                    <th (click)="partSort('popId')">
                                      {{ 'sequences.popId' | translate }}
                                      &nbsp;<i class="fa fa-sort"></i>
                                    </th>
                                    <th (click)="partSort('time')">
                                      {{ 'sequences.time' | translate }}
                                      &nbsp;<i class="fa fa-sort"></i>
                                    </th>
                                  </tr>
                                </thead>
                                <tbody>
                                  <ng-container *ngFor="let part of pack.Parts | orderBy: packKey:packReverse">
                                    <tr [ngClass]="part.select ? 'table-primary' : isMatch(part.id) ? 'match' : ''">
                                      <td>{{ part.itemCode }}</td>
                                      <td>{{ part.cuCode }}</td>
                                      <td>{{ part.grossItemCode }}</td>
                                      <td>{{ part.color }}</td>
                                      <td>{{ part.colorCode }}</td>
                                      <td>{{ part.popId }}</td>
                                      <td>{{ part.time }}</td>
                                    </tr>
                                  </ng-container>
                                </tbody>
                              </table>
                            </div>
                          </td>
                        </tr>
                      </ng-container>
                    </tbody>
                  </table>
                </div>
              </td>
            </tr>
          </ng-container>
        </tbody>
      </table>

      <div *ngIf="packSelectError" class="alert alert-danger" role="alert" style="white-space: pre-wrap">
        {{ packSelectErrorMessage }}
      </div>

      <div class="d-grid d-md-flex justify-content-md-end">
        <button mat-button color="primary" matStepperPrevious>
          {{ 'expedition.back' | translate }}
        </button>
        &nbsp;&nbsp;
        <button
          *ngIf="!intermediaryExpedition.id && !buttonLoading"
          mat-flat-button
          color="primary"
          [disabled]="verifyPrintList() || loading || buttonLoading"
          (click)="verifyItems(stepper, step)">
          {{ 'expedition.generateExpedition' | translate }}
        </button>
        <button
          *ngIf="intermediaryExpedition.id && !buttonLoading"
          mat-flat-button
          color="primary"
          [disabled]="verifyPrintList() || loading || buttonLoading"
          (click)="verifyItems(stepper, step)">
          {{ 'expedition.editExpedition' | translate }}
        </button>
        <button
          *ngIf="buttonLoading"
          mat-flat-button
          color="primary"
          [disabled]="verifyPrintList() || loading || buttonLoading">
          <div *ngIf="buttonLoading" class="d-flex justify-content-center" style="margin: 10% auto">
            <div class="spinner-border text-primary" role="status">
              <span class="sr-only">Loading...</span>
            </div>
          </div>
        </button>
      </div>
    </mat-step>

    <mat-step editable="false">
      <ng-template matStepLabel>{{ 'expedition.printList' | translate }}</ng-template>
      <div class="alert alert-success" role="alert">
        {{ 'expedition.expeditionSuccess' | translate }}
      </div>
      <p>{{ 'expedition.popidsInTheList' | translate }}:</p>
      <ul>
        <li *ngFor="let popid of uniquePopidList">
          {{ popid.popId }}
        </li>
      </ul>
      <div class="d-grid d-md-flex justify-content-md-end">
        <button mat-button (click)="stepper.reset()">
          {{ 'expedition.generateAnotherExpedition' | translate }}
        </button>
        <button mat-flat-button color="primary" (click)="goToPrint()">
          {{ 'expedition.print' | translate }}
        </button>
      </div>
    </mat-step>
  </mat-stepper>

  <!-- Table pagination (p is the current page)-->
  <!-- <pagination-controls (pageChange)="pageNumber = $event"></pagination-controls> -->

  <!-- Print buttons -->
</div>
