<!--
File: home.component.html
Project: boxpart-console
File Created: Thursday, 14th October 2021 10:23:31 am
Author: Jorge Herrera (jfelix@vonbraunlabs.com.br)
-----
Copyright 2021 - VB Lab.
-----
HISTORY:
Date      	By	Comments
----------	---	---------------------------------------------------------
2021-10-25	TC	Translate language (english to portuguese)
2021-10-21	TC	Added content class.
2021-10-14	JH 	Home page layout.
-->

<div class="content">
  <h3 cs>
    {{ 'home.title' | translate }}
  </h3>
  <p>
    {{ 'home.description1' | translate }}
    <br />
    {{ 'home.description2' | translate }}
  </p>
</div>
