<div class="dashboard-container">
  <div class="overall-bar">
    <div class="title">
      <div class="left-elements">
        Embalagens
        <span style="margin: 0 1rem">•</span>
        <input type="date" class="form-control" placeholder="Date" name="date" [(ngModel)]="date" (change)="update()" />
      </div>
      <img src="../../../assets//images/boxpart-minilogo.svg" />
    </div>
    <div class="bar">
      <div [style.width]="((boxQuantityPerState[0] / total) * 100).toString() + '%'" class="available"></div>
      <div [style.width]="((boxQuantityPerState[1] / total) * 100).toString() + '%'" class="opened"></div>
      <div [style.width]="((boxQuantityPerState[2] / total) * 100).toString() + '%'" class="suspended"></div>
      <div [style.width]="((boxQuantityPerState[3] / total) * 100).toString() + '%'" class="closed"></div>
      <div [style.width]="((boxQuantityPerState[4] / total) * 100).toString() + '%'" class="dispatched"></div>
    </div>
    <div class="description">
      <div>
        <i class="fas fa-circle" style="color: #c02f1d"></i>
        {{ boxQuantityPerState[0] }} <br />
        Disponíveis
      </div>
      <div>
        <i class="fas fa-circle" style="color: #f26d21"></i>
        {{ boxQuantityPerState[1] }} <br />
        Abertas
      </div>
      <div>
        <i class="fas fa-circle" style="color: #0d3d56"></i>
        {{ boxQuantityPerState[2] }} <br />
        Suspensas
      </div>
      <div>
        <i class="fas fa-circle" style="color: #ebc944"></i>
        {{ boxQuantityPerState[3] }} <br />
        Fechadas
      </div>
      <div>
        <i class="fas fa-circle" style="color: #a3b86c"></i>
        {{ boxQuantityPerState[4] }} <br />
        Despachadas
      </div>
    </div>
  </div>
  <div class="detail-container">
    <!-- BUMPER AREA -->
    <div class="bumper-area">
      <div class="title">Bumper</div>
      <div class="emptyKitting" *ngIf="boxQuantityPerState[1] + boxQuantityPerState[2] === 0">
        Nenhuma embalagem em trabalho
      </div>
      <table class="table table-hover table-bordered" *ngIf="boxQuantityPerState[1] + boxQuantityPerState[2] > 0">
        <thead>
          <tr>
            <th>Embalagem</th>
            <th>Responsável</th>
            <th>Status</th>
            <th>Empacotamento</th>
            <th><i class="fas fa-cogs"></i></th>
          </tr>
        </thead>
        <tbody>
          <tr *ngFor="let box of boxes.filter(filterBumpers).slice(0, 10)" class="align-middle">
            <td>{{ box.supplier }}_{{ box.refNumber }}</td>
            <td>{{ box.operator }}</td>
            <td>
              {{ boxMetadata[box.status].namePT }}
              <i class="fas fa-exclamation-circle" *ngIf="box.status === 1" style="color: rgb(225, 76, 76)"></i>
            </td>
            <td>
              <div class="packaging-bar">
                <div
                  class="complete-bar"
                  [style.width]="((box.currentParts / box.numberParts) * 100).toString() + '%'"></div>
              </div>
            </td>
            <td>{{ box.currentParts }}/{{ box.numberParts }}</td>
          </tr>
        </tbody>
      </table>
    </div>

    <!-- DEFLECTOR AREA -->
    <div class="deflector-area">
      <div class="title">Defletor</div>
      <div class="emptyKitting" *ngIf="boxQuantityPerState[1] + boxQuantityPerState[2] === 0">
        Nenhuma embalagem em trabalho
      </div>
      <table class="table table-hover table-bordered" *ngIf="boxQuantityPerState[1] + boxQuantityPerState[2] > 0">
        <thead>
          <tr>
            <th>Embalagem</th>
            <th>Responsável</th>
            <th>Status</th>
            <th>Empacotamento</th>
            <th><i class="fas fa-cogs"></i></th>
          </tr>
        </thead>
        <tbody>
          <tr *ngFor="let box of boxes.filter(filterDeflectors).slice(0, 10)" class="align-bottom">
            <td>{{ box.supplier }}_{{ box.refNumber }}</td>
            <td>{{ box.operator }}</td>
            <td>{{ boxMetadata[box.status].namePT }}</td>
            <td>
              <div class="packaging-bar">
                <div
                  class="complete-bar"
                  [style.width]="((box.currentParts / box.numberParts) * 100).toString() + '%'"></div>
              </div>
            </td>
            <td>{{ box.currentParts }}/{{ box.numberParts }}</td>
          </tr>
        </tbody>
      </table>
    </div>

    <!-- Mudguard AREA -->
    <div class="mudguard-area">
      <div class="title">Mudguard</div>
      <div class="emptyKitting" *ngIf="boxQuantityPerState[1] + boxQuantityPerState[2] === 0">
        Nenhuma embalagem em trabalho
      </div>
      <table class="table table-hover table-bordered" *ngIf="boxQuantityPerState[1] + boxQuantityPerState[2] > 0">
        <thead>
          <tr>
            <th>Embalagem</th>
            <th>Responsável</th>
            <th>Status</th>
            <th>Empacotamento</th>
            <th><i class="fas fa-cogs"></i></th>
          </tr>
        </thead>
        <tbody>
          <tr *ngFor="let box of boxes.filter(filterMudguards).slice(0, 10)" class="align-middle">
            <td>{{ box.supplier }}_{{ box.refNumber }}</td>
            <td>{{ box.operator }}</td>
            <td>
              {{ boxMetadata[box.status].namePT }}
              <i class="fas fa-exclamation-circle" *ngIf="box.status === 1" style="color: rgb(225, 76, 76)"></i>
            </td>
            <td>
              <div class="packaging-bar">
                <div
                  class="complete-bar"
                  [style.width]="((box.currentParts / box.numberParts) * 100).toString() + '%'"></div>
              </div>
            </td>
            <td>{{ box.currentParts }}/{{ box.numberParts }}</td>
          </tr>
        </tbody>
      </table>
    </div>
  </div>

  <div class="expedition-container">
    <div class="title">Expedições de Hoje</div>

    <div
      class="emptyExpeditions"
      *ngIf="activeExpeditions.length + othersExpeditions.length + endedExpeditions.length === 0">
      Nenhuma expedição criada
    </div>

    <div
      class="expedition-cards"
      *ngIf="activeExpeditions.length + othersExpeditions.length + endedExpeditions.length > 0">
      <div class="ended-expedition-card" *ngFor="let expedition of endedExpeditions">
        <div class="expedition-title">
          <span>
            {{ expedition.expeditionTransportType }}<br />
            <strong>{{ expedition.expeditionVehicleType }}</strong>
          </span>
          <div class="ok-indicator"></div>
        </div>
        <div class="bottom">
          <strong>{{ expedition.expeditionDate | date: 'shortTime' }}</strong>
          <div class="expedition-number">
            <i class="fas fa-box"></i>
            {{ expedition.inTruckBoxCount }}
          </div>
        </div>
      </div>

      <div class="active-expedition-card" *ngFor="let expedition of activeExpeditions">
        <div class="expedition-title">
          <span>
            {{ expedition.expeditionTransportType }} -
            <strong>{{ expedition.expeditionVehicleType }}</strong>
          </span>
          <span>
            <strong>
              {{ expedition.expeditionDate | date: 'shortTime' }}
            </strong>
          </span>
          <div class="spinner-grow text-danger" role="status"></div>
        </div>
        <div class="expedition-content">
          <div class="expedition-bar">
            <div
              class="complete-bar"
              [style.width]="((expedition.inTruckBoxCount / expedition.allBoxCount) * 100).toString() + '%'"></div>
          </div>
          <div class="expedition-number">
            <i class="fas fa-box"></i>
            {{ expedition.inTruckBoxCount }}/{{ expedition.allBoxCount }}
          </div>
        </div>
      </div>

      <div class="expedition-card" *ngFor="let expedition of othersExpeditions">
        <div class="expedition-title">
          <span
            >{{ expedition.expeditionTransportType }} - <strong>{{ expedition.expeditionVehicleType }}</strong></span
          >
          <span
            ><strong>{{ expedition.expeditionDate | date: 'shortTime' }}</strong></span
          >
        </div>
        <div class="expedition-content">
          <div class="expedition-bar">
            <div
              class="complete-bar"
              [style.width]="((expedition.inTruckBoxCount / expedition.allBoxCount) * 100).toString() + '%'"></div>
          </div>
          <div class="expedition-number">
            <i class="fas fa-box"></i>
            {{ expedition.inTruckBoxCount }}/{{ expedition.allBoxCount }}
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
