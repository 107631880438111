import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { expeditionReprintDto } from '@atypes/expedition.types';

@Component({
  selector: 'app-expedition-print',
  templateUrl: './expedition-print.component.html',
  styleUrls: ['./expedition-print.component.scss'],
})
export class ExpeditionPrintComponent implements OnInit {
  formHeader: {
    collectTime: string;
    transportType: string;
    vehicleType: string;
  };
  formatedDate = '';
  formatedTime = '';
  quantityPerPage = 15;

  uniquePopidList: expeditionReprintDto[] = [];
  popIdGroups: expeditionReprintDto[][] = [];

  constructor(route: ActivatedRoute) {
    const params = JSON.parse(route.snapshot.params['expedition']);
    this.formHeader = params.formHeader;
    this.uniquePopidList = params.uniquePopidList;
    this.formatedDate = new Date(
      this.formHeader.collectTime
    ).toLocaleDateString(navigator.language, {
      year: 'numeric',
      month: 'numeric',
      day: 'numeric',
    });
    this.formatedTime = new Date(
      this.formHeader.collectTime
    ).toLocaleTimeString(navigator.language, {
      hour: '2-digit',
      minute: '2-digit',
    });

    this.quantityPerPage = 15;
    for (
      let i = 0;
      i < this.uniquePopidList.length;
      i += this.quantityPerPage
    ) {
      this.popIdGroups.push(
        this.uniquePopidList.slice(i, i + this.quantityPerPage)
      );
    }
  }

  ngOnInit(): void {
    return;
  }
}
