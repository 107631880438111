/*
 * File: apptoast.component.ts
 * Project: boxpart-console
 * File Created: Thursday, 14th October 2021 12:46:01 pm
 * Author: Jorge Herrera (jfelix@vonbraunlabs.com.br)
 * -----
 * Copyright 2021 - Von Braun Labs.
 * -----
 * HISTORY:
 * Date      	By	Comments
 * ----------	---	---------------------------------------------------------
 * 2021-10-14	JH	Toast rendering.
 */

import { Component, TemplateRef } from '@angular/core';
import { AppToastService } from '@services/apptoast.service';

@Component({
  selector: 'app-toasts',
  templateUrl: './apptoast.template.html',
  styles: [],
  // eslint-disable-next-line @angular-eslint/no-host-metadata-property
  host: { '[class.ngb-toasts]': 'true' },
})
export class AppToastsComponent {
  constructor(public toastService: AppToastService) {}

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  isTemplate(toast: any) {
    return toast.textOrTpl instanceof TemplateRef;
  }
}
