<div
  style="
    display: flex;
    align-items: center;
    flex-direction: column;
    border: 2px #eaeaea solid;
    border-radius: 6px;
    margin: 1rem 5rem;
    padding: 1rem;
  "
  *ngFor="let tagGroup of tags">
  <h2 style="margin: 0">Etiqueta B</h2>
  <h4 style="margin: 0">{{ tagGroup.bCode }}</h4>
  <div class="qrcodeImage">
    <qrcode
      qrdata="{{ tagGroup.bCode }}"
      [allowEmptyString]="true"
      [ariaLabel]="'QR Code image with the following content...'"
      [cssClass]="'left'"
      colorLight="#ffffff00"
      [elementType]="'canvas'"
      [errorCorrectionLevel]="'M'"
      [scale]="1"
      [title]="'A custom title attribute'"
      [width]="250"></qrcode>
  </div>

  <div
    style="width: 100%; display: flex; align-items: center; justify-content: center"
    *ngFor="let partTags of tagGroup.parts">
    <div style="display: flex; flex-direction: column; flex-grow: 1; align-items: center">
      <h3 style="margin: 0">Etiqueta C</h3>
      <h4 style="margin: 0">{{ partTags.cCode }}</h4>
      <qrcode
        qrdata="{{ partTags.cCode }}"
        [allowEmptyString]="true"
        [ariaLabel]="'QR Code image with the following content...'"
        [cssClass]="'left'"
        colorLight="#ffffff00"
        [elementType]="'canvas'"
        [errorCorrectionLevel]="'M'"
        [scale]="1"
        [title]="'A custom title attribute'"
        [width]="250"></qrcode>
    </div>

    <div style="display: flex; flex-direction: column; flex-grow: 1; align-items: center">
      <h3 style="margin: 0">Etiqueta A</h3>
      <h4 style="margin: 0">{{ partTags.aCode }}</h4>
      <qrcode
        qrdata="{{ partTags.aCode }}"
        [allowEmptyString]="true"
        [ariaLabel]="'QR Code image with the following content...'"
        [cssClass]="'left'"
        colorLight="#ffffff00"
        [elementType]="'canvas'"
        [errorCorrectionLevel]="'M'"
        [scale]="1"
        [title]="'A custom title attribute'"
        [width]="250"></qrcode>
    </div>
  </div>
</div>
