import TagPlaceHolders from './models/TagPlaceHolders';
import { Component, OnInit } from '@angular/core';
import Printer from './models/Printer';
import { HttpClient } from '@angular/common/http';

import { BrowserPrint } from './js/BrowserPrint';

/** Provides printer interface dynamic. */
@Component({
  selector: 'app-printer',
  templateUrl: './printer.component.html',
  styleUrls: ['./printer.component.css'],
})
export class PrinterComponent implements OnInit {
  printers: Printer[] = [];
  private httpClient: HttpClient;
  private fileTagTemplate = '';

  constructor(http: HttpClient) {
    this.httpClient = http;
  }

  ngOnInit(): void {
    BrowserPrint.getLocalDevices(response => {
      console.log('Device List', response);
      this.printers = response.printer;
    });

    this.httpClient
      .get('assets/label/etiqueta.prn', {
        responseType: 'text',
      })
      .subscribe(fileTagTemplate => {
        this.fileTagTemplate = fileTagTemplate;
        console.log(fileTagTemplate);
      });
  }

  /** Substitute placeHolders in the template */
  private getTagText(tagTemplate, placeHolders) {
    const finalTag = this.substituteText(tagTemplate, placeHolders);
    console.log('String replaced', finalTag);
    return finalTag;
  }

  public print(uid) {
    console.log('uid', uid);
    const placeHolders: TagPlaceHolders = {
      TIPO_VAL: 'DEFLETOR',
      FORN_VAL: 'CBU',
      COLOR_VAL: 'WHI',
      PN_VAL: '1234567',
      POPID_VAL: '987654',
      C_U_VAL: '7777',
      CODE_VAL: 'WHI1234567POPID987654',
      DATA_HORIZ_VAL: '2021-10-22',
      HORA_HORIZ_VAL: '14:02',
      DATA_VERT_VAL: '22/10/2021',
      NAME: 'Bruno',
    } as TagPlaceHolders;

    const tagText = this.getTagText(this.fileTagTemplate, placeHolders);

    let localDevices = [];
    BrowserPrint.getLocalDevices(async response => {
      console.log('Device List', response.printer);
      localDevices = response.printer;
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      const selectedDevice: any = localDevices.filter((device: Printer) => {
        return device.uid === uid;
      })[0];
      console.log('Selected device: ', selectedDevice);
      selectedDevice.send(
        tagText,
        result => console.log(result),
        error => console.log(error)
      );
    });
  }

  private substituteText(text: string, replacements: TagPlaceHolders) {
    const str = text.replace(/{(\w+)}/g, function (all) {
      const key: string = all.replace('{', '').replace('}', '');
      return replacements[key] || all;
    });
    return str;
  }
}
