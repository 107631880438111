import { IExpeditionDto } from './../expedition-list/expedition-list.component';
import { Component, Inject, LOCALE_ID, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Router, ActivatedRoute } from '@angular/router';

import { PrinterService } from 'src/app/services/printer.service';
import { SequencesService } from 'src/app/services/sequences.service';
import { Sequences, Packs, Parts } from '../../common/types/sequences.types';
import { TranslateService } from '@ngx-translate/core';
import { AppToastService } from '@services/apptoast.service';
import { formatDate } from '@angular/common';
import { expeditionReprintDto } from '@atypes/expedition.types';
import { boxIdentifier } from '@atypes/box.types';
import { NgbCalendar, NgbDate } from '@ng-bootstrap/ng-bootstrap';
import { ErrorMessageService } from '@services/error-message.service';
import { MatStep, MatStepper } from '@angular/material/stepper';

export interface IntermediaryExpeditionInterface {
  date: NgbDate;
  time: string;
  id: number;
  status: number;
  transportType: string;
  vehicleType: string;
}

@Component({
  selector: 'app-expedition',
  templateUrl: './expedition.component.html',
  styleUrls: ['./expedition.component.scss'],
})
export class ExpeditionComponent implements OnInit {
  expedition: Partial<IExpeditionDto> = {};
  intermediaryExpedition: Partial<IntermediaryExpeditionInterface> = {};
  expeditionBoxes: boxIdentifier[] = [];

  firstFormGroup: FormGroup;

  isEditable = true;
  packSelectError = false;
  packSelectErrorMessage = '';
  uniquePopidList: expeditionReprintDto[] = [];

  // Loading until waiting API GET
  loading?: boolean = true;
  buttonLoading?: boolean = false;

  // Empty result in filtering
  emptyResult?: boolean = false;

  // Show filter area
  filtering?: boolean = true;

  // Sequences array
  sequences?: Sequences[] = [];

  // Start date and end date for request -> /sequence/{sequenceType}/{startDate}/{endDate}
  startDateInput: string;
  endDateInput: string;
  startDate?: string = '';
  endDate?: string = '';
  sequenceType = 'all'; // Sequence type filter (default = all)
  selectSearchType?: string;
  matchArray: number[] = [];

  searchInput: string; // Input field text
  pageNumber?: number = 1; // Page number
  printDisabled = true; // True if print buttons are disabled

  /** Sorting variables */

  // Sort sequence columns in the table
  key?: string = 'date'; // Initializing sort method (using date field)
  reverse?: boolean = true;

  // Sort pack columns in the table
  packKey?: string = ''; // Initializing sort method
  packReverse?: boolean = true;

  // Sort part columns in the table
  partKey?: string = ''; // Initializing sort method
  partReverse?: boolean = true;

  /** Selecting/check variables */
  selectCount: number; // Count of selected checkboxes
  indeterminate?: boolean = false; // Indeterminate in SelectAll checkbox
  selectAllCheck?: boolean = false; // Check all checkboxes

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  subscribe: any;

  /** Default class constructor */
  constructor(
    @Inject(LOCALE_ID) public locale: string,
    private sequenceService: SequencesService,
    private printerService: PrinterService,
    private toast: AppToastService,
    private translate: TranslateService,
    private router: Router,
    private route: ActivatedRoute,
    private _formBuilder: FormBuilder,
    private calendar: NgbCalendar,
    private errorMessageService: ErrorMessageService
  ) {
    this.searchInput = '';
    this.startDateInput = '';
    this.endDateInput = '';
    this.selectCount = 0;
    this.selectSearchType = 'popId';

    this.firstFormGroup = this._formBuilder.group({
      transportType: [, Validators.required],
      collectDate: [, Validators.required],
      collectTime: [, Validators.required],
      vehicleType: [, Validators.required],
    });
  }

  /** Load initial sequence set. */
  async ngOnInit() {
    this.route.paramMap.subscribe(parameterMap => {
      const expedition = JSON.parse(parameterMap.get('expedition') ?? '');
      this.getExpedition(expedition);
    });
    if (this.intermediaryExpedition.id) {
      await this.sequenceService
        .getExpeditonBoxesById(this.intermediaryExpedition.id)
        .toPromise()
        .then(boxes => {
          this.expeditionBoxes = boxes;

          let smallestDate = new Date(8640000000000000);
          let biggestDate = new Date(-8640000000000000);

          boxes.map(box => {
            if (new Date(box.sequenceDate).getTime() < smallestDate.getTime()) {
              smallestDate = new Date(box.sequenceDate);
            }
            if (new Date(box.sequenceDate).getTime() > biggestDate.getTime()) {
              biggestDate = new Date(box.sequenceDate);
            }
          });

          this.startDate = smallestDate.toISOString();
          this.endDate = biggestDate.toISOString();
          this.startDateInput = formatDate(
            smallestDate,
            'yyyy-MM-dd',
            navigator.language
          );
          this.endDateInput = formatDate(
            biggestDate,
            'yyyy-MM-dd',
            navigator.language
          );
        });
    }

    this.reloadSequences();
  }

  getExpedition(expedition: IExpeditionDto) {
    if (expedition) {
      const b = expedition.dateTime.split(/\D+/);
      const date = new Date(
        Date.UTC(
          Number(b[0]),
          Number(b[1]) - 1,
          Number(b[2]),
          Number(b[3]),
          Number(b[4]),
          Number(b[5]),
          Number(b[6])
        )
      );

      const expeditionIntermediary: Partial<IntermediaryExpeditionInterface> = {
        id: expedition.id,
        status: expedition.status,
        transportType: expedition.transportType,
        vehicleType: expedition.vehicleType,
        date: new NgbDate(
          date.getUTCFullYear(),
          date.getUTCMonth() + 1,
          date.getUTCDate()
        ),
        time:
          ('0' + date.getHours()).slice(-2) +
          ':' +
          ('0' + date.getMinutes()).slice(-2),
      };

      this.intermediaryExpedition = {
        id: expeditionIntermediary.id,
        transportType: expeditionIntermediary.transportType,
        vehicleType: expeditionIntermediary.vehicleType,
        date: expeditionIntermediary.date,
        time: expeditionIntermediary.time,
      };

      this.firstFormGroup = this._formBuilder.group({
        transportType: [
          expeditionIntermediary.transportType,
          Validators.required,
        ],
        collectDate: [expeditionIntermediary.date, Validators.required],
        collectTime: [expeditionIntermediary.time, Validators.required],
        vehicleType: [expeditionIntermediary.vehicleType, Validators.required],
      });
    } else {
      const expeditionIntermediary: Partial<IntermediaryExpeditionInterface> = {
        transportType: 'Milk Run',
        vehicleType: 'Caminhonete',
        date: this.calendar.getToday(),
        time:
          ('0' + new Date().getHours()).slice(-2) +
          ':' +
          ('0' + new Date().getMinutes()).slice(-2),
      };

      this.intermediaryExpedition = {
        transportType: expeditionIntermediary.transportType,
        vehicleType: expeditionIntermediary.vehicleType,
        date: expeditionIntermediary.date,
        time: expeditionIntermediary.time,
      };

      this.firstFormGroup = this._formBuilder.group({
        transportType: [
          expeditionIntermediary.transportType,
          Validators.required,
        ],
        collectDate: [expeditionIntermediary.date, Validators.required],
        collectTime: [expeditionIntermediary.time, Validators.required],
        vehicleType: [expeditionIntermediary.vehicleType, Validators.required],
      });
    }
  }

  async reloadSequences() {
    // Start loading
    this.loading = true;

    // Change date input range to the default search
    if (this.startDate == '' && this.endDate == '') {
      const currentdate = new Date();
      currentdate.setDate(currentdate.getDate() + 15);
      this.endDateInput = formatDate(
        currentdate,
        'yyyy-MM-dd',
        navigator.language
      );
      currentdate.setDate(currentdate.getDate() - 30);
      this.startDateInput = formatDate(
        currentdate,
        'yyyy-MM-dd',
        navigator.language
      );
    }

    // Get sequences and check if is empty
    this.sequenceService
      .getSequences(this.sequenceType, this.startDate, this.endDate)
      .subscribe(
        sequences => {
          if (!sequences.length) this.emptyResult = true;
          else this.emptyResult = false;
          this.sequences = sequences;
          this.loading = false;

          if (this.intermediaryExpedition.id) {
            sequences.map(async sequence => {
              if (
                this.expeditionBoxes.some(
                  box => box.sequenceDate === sequence.date
                )
              ) {
                await this.sequenceToggle(sequence);
                sequence.Packs?.map(pack => {
                  if (
                    this.expeditionBoxes.some(
                      box => box.refNumber === pack.refNumber
                    )
                  ) {
                    this.packCheck(sequence, pack);
                  }
                });
              }
            });
          }
        },
        // Error in get sequences
        sequenceError => {
          this.emptyResult = true;
          this.loading = false;
          this.toast.error(
            this.errorMessageService.translate(sequenceError.error.code)
          );
        }
      );
  }

  /** Handles Filter button pressing. */
  onApplyFilter(): void {
    this.filtering = !this.filtering;
  }

  /** Handles Search button pressing. */
  onSearch() {
    if (this.subscribe) this.subscribe.unsubscribe();

    // Reset empty result state and update startDate and endDate with timezone;
    this.emptyResult = false;
    this.startDate = this.startDateInput;
    this.endDate = this.endDateInput;

    if (this.startDate != '') this.startDate += ' GMT-0300';
    if (this.endDate != '') this.endDate += ' GMT-0300';

    if (this.searchInput == '') {
      this.reloadSequences(); // Reset all: ;
    } else {
      // Reload sequences with the filter
      // TODO: Comment, adjust logic and improve calls
      this.matchArray = [];
      this.sequences = [];
      let loadingCount = 0;
      this.loading = true;
      if (this.selectSearchType) {
        this.subscribe = this.sequenceService
          .getSearch(
            this.selectSearchType,
            this.searchInput,
            this.sequenceType,
            this.startDate,
            this.endDate
          )
          .subscribe(
            res => {
              if (res.length > 0) {
                res.map(result => {
                  if (result.assemblyDetailId)
                    this.matchArray.push(result.assemblyDetailId);
                  if (result.sequenceId)
                    this.sequenceService
                      .getSequenceById(result.sequenceId)
                      .subscribe(
                        sequenceRes => {
                          if (
                            !this.sequences?.find(
                              sequence => sequence.id == result.sequenceId
                            )
                          ) {
                            this.sequences?.push(sequenceRes[0]);
                            if (this.sequences) {
                              const sequence =
                                this.sequences[this.sequences?.length - 1];
                              sequence.loadingPacks = true;
                              sequence.toggle = true;
                              this.sequenceService
                                .loadPacks(sequence)
                                .subscribe(
                                  packs => {
                                    sequence.Packs = packs;
                                    sequence.loadingPacks = false;
                                    sequence.Packs.map(pack => {
                                      pack.loadingParts = true;
                                      this.sequenceService
                                        .loadParts(pack)
                                        .subscribe(
                                          parts => {
                                            pack.Parts = parts;
                                            pack.loadingParts = false;
                                            pack.Parts.map(part => {
                                              if (
                                                part.id &&
                                                this.isMatch(part.id)
                                              ) {
                                                pack.toggle = true;
                                                loadingCount++;
                                              }
                                            });
                                            if (loadingCount == res.length)
                                              this.loading = false;
                                          },
                                          partsError => {
                                            pack.loadingParts = false;
                                            sequence.loadingPacks = false;
                                            this.loading = false;
                                            this.toast.error(
                                              this.errorMessageService.translate(
                                                partsError.error.code
                                              )
                                            );
                                          }
                                        );
                                    });
                                  },
                                  packsError => {
                                    sequence.loadingPacks = false;
                                    this.loading = false;
                                    this.toast.error(
                                      this.errorMessageService.translate(
                                        packsError.error.code
                                      )
                                    );
                                  }
                                );
                            }
                          }
                        },
                        sequenceError => {
                          this.loading = false;
                          this.toast.error(
                            this.errorMessageService.translate(
                              sequenceError.error.code
                            )
                          );
                        }
                      );
                });
              } else {
                this.loading = false;
                this.emptyResult = true;
              }
            },
            searchError => {
              this.loading = false;
              this.toast.error(
                this.errorMessageService.translate(searchError.error.code)
              );
            }
          );
      }
    }
  }

  /** Part is in result of search? */
  isMatch(id: number) {
    if (this.matchArray?.includes(id)) {
      return true;
    }
    return false;
  }

  clearFilters() {
    this.searchInput = '';
    this.endDate = '';
    this.endDateInput = '';
    this.startDateInput = '';
    this.startDate = '';
    this.sequenceType = 'all';
  }

  /** Sorting sequence columns in the table */
  sort(key: string): void {
    this.key = key; // Key for sort
    this.reverse = !this.reverse; // Sort order
  }

  /** Sorting packs columns in the table */
  packSort(packKey: string): void {
    this.packKey = packKey; // Key for sort
    this.packReverse = !this.packReverse; // Sort order
  }

  /** Sorting parts columns in the table */
  partSort(partKey: string): void {
    this.partKey = partKey; // Key for sort
    this.partReverse = !this.partReverse; // Sort order
  }

  /** Select all box using selectCount variable and map to select all sub-tables */
  selectAll(): void {
    this.selectAllCheck = !this.selectAllCheck;
    if (this.selectAllCheck) this.selectCount = this.sequences?.length ?? 0;
    else this.selectCount = 0;

    this.sequences?.map(sequence => {
      sequence.select = this.selectAllCheck;

      if (!sequence.Packs?.length) {
        this.sequenceService.loadPacksSelected(sequence, this.selectAllCheck);
      } else {
        sequence.Packs.map(pack => {
          pack.select = this.selectAllCheck;
          if (pack.Parts?.length) {
            pack.Parts.map(part => (part.select = this.selectAllCheck));
          } else {
            this.sequenceService.loadPartsSelected(pack, this.selectAllCheck);
          }
        });
      }
    });
  }

  /** Check a individual sequence and all sub-tables (packs and parts) */
  check(sequence): void {
    // Increment or Decrement a selectCount on check
    if (sequence.select) this.selectCount--;
    else this.selectCount++;

    // Change element check state
    sequence.select = !sequence.select;

    if (!sequence.Packs?.length) {
      this.sequenceService.loadPacksSelected(sequence, sequence.select);
    } else {
      sequence.Packs.map(pack => {
        pack.select = sequence.select;
        if (!pack.Parts?.length) {
          this.sequenceService.loadPartsSelected(pack, sequence.select);
        } else {
          pack.Parts.map(part => (part.select = sequence.select));
        }
      });
    }

    // None - if no one is selected
    if (this.selectCount == 0) {
      this.indeterminate = false;
      this.selectAllCheck = false;
    }
    // Check - if all is selected
    else if (this.selectCount == this.sequences?.length) {
      this.indeterminate = false;
      this.selectAllCheck = true;
    }
    // Indeterminate - else
    else {
      this.selectAllCheck = false;
      this.indeterminate = true;
    }
  }

  // Check a individual pack and your parts
  packCheck(sequence: Sequences, pack: Packs): void {
    pack.select = !pack.select;
    pack.loadingParts = true;

    // Select sequence if all packs is selected
    if (sequence.Packs?.filter(pack => pack.select).length === sequence.packs)
      sequence.select = true;
    else sequence.select = false;

    // Load parts if is not loaded and select them
    if (!pack.Parts?.length) {
      this.sequenceService.loadPartsSelected(pack, pack.select);
    } else {
      pack.Parts.map(part => (part.select = pack.select));
      pack.loadingParts = false;
    }
  }

  // Check a individual part
  partCheck(sequence: Sequences, pack: Packs, part: Parts): void {
    part.select = !part.select;

    // Select pack if all parts is selected; Select sequence if all packs is selected
    if (pack.Parts?.filter(part => part.select).length === pack.Parts?.length) {
      pack.select = true;
      if (sequence.Packs?.filter(pack => pack.select).length === sequence.packs)
        sequence.select = true;
      else sequence.select = false;
    } else {
      pack.select = false;
      if (sequence.Packs?.filter(pack => pack.select).length === sequence.packs)
        sequence.select = true;
      else sequence.select = false;
    }
  }

  // Transform 2021-10-17T00:00:00 to 17/10/2021 (DD/MM/AAAA) -> Display for user
  transformDateFormatToTable(date: string) {
    return formatDate(Date.parse(date), 'shortDate', this.locale);
  }

  // Max date allowed to select in input end date
  maxDate(date: string): string {
    if (!date) return '';
    const newDate = new Date(date);
    newDate.setDate(newDate.getDate() + 30);
    return formatDate(
      Date.parse(newDate.toString()),
      'yyyy-MM-dd',
      navigator.language
    );
  }

  // Toggle button for Sequences Table
  async sequenceToggle(sequence: Sequences) {
    sequence.toggle = !sequence.toggle;
    // If sequence packs already exists, return
    if (sequence.Packs?.length) return;
    // Load packs from server
    sequence.loadingPacks = true;
    await this.sequenceService
      .loadPacks(sequence)
      .toPromise()
      .then(
        packs => {
          sequence.Packs = packs;
          sequence.loadingPacks = false;
        },
        // Error in get sequences
        packsError => {
          sequence.loadingPacks = false;
          this.toast.error(
            this.errorMessageService.translate(packsError.error.code)
          );
        }
      );
    await this.sequenceService
      .loadPacks(sequence)
      .toPromise()
      .then(
        packs => {
          sequence.Packs = packs;
          sequence.loadingPacks = false;
        },
        // Error in get sequences
        packsError => {
          sequence.loadingPacks = false;
          this.toast.error(
            this.errorMessageService.translate(packsError.error.code)
          );
        }
      );
  }

  // Toggle button for Packs Table
  packToggle(pack: Packs): void {
    pack.toggle = !pack.toggle;
    // If pack parts already exists, return
    if (pack.Parts?.length) return;
    // Load parts from server
    pack.loadingParts = true;
    this.sequenceService.loadParts(pack).subscribe(
      parts => {
        pack.Parts = parts;
        pack.Parts.map(part => {
          part.select = pack.select;
        });
        pack.loadingParts = false;
      },
      // Error in get sequences
      partsError => {
        pack.loadingParts = false;
        this.toast.error(
          this.errorMessageService.translate(partsError.error.code)
        );
      }
    );
  }

  // Verify if have any selected items
  verifyPrintList(): boolean {
    // Start disabled
    let disable = true;
    this.sequences?.map(sequence => {
      // If any sequence is loading packs disable button and cancel the verification
      if (sequence.loadingPacks) {
        disable = true;
        return;
      }
      sequence.Packs?.map(pack => {
        // If any pack is loading parts disable button and cancel the verification
        if (pack.loadingParts) {
          disable = true;
          return;
        }
        pack.Parts?.map(part => {
          // If any part is selected enable button
          if (part.select) disable = false;
        });
      });
    });
    return disable;
  }

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  async verifyItems(stepper: MatStepper, step: MatStep) {
    const printList: Packs[] = [];
    // Get each selected pack
    this.sequences?.map(sequence => {
      sequence.Packs?.map(pack => {
        if (pack.select) printList.push(pack);
      });
    });

    const id = this.intermediaryExpedition.id;
    const transportType = this.firstFormGroup.get('transportType')?.value;
    const vehicleType = this.firstFormGroup.get('vehicleType')?.value;
    const collectDate = this.firstFormGroup.get('collectDate')?.value;
    const collectTime = this.firstFormGroup.get('collectTime')?.value;
    const assemblyPackIds = printList.map(item => item.id);

    this.packSelectError = false;
    this.packSelectErrorMessage = '';
    this.buttonLoading = true;
    this.isEditable = !this.buttonLoading;

    if (collectTime == '') {
      this.packSelectError = true;
      this.packSelectErrorMessage = '\n- Preencha o horário da coleta.';
    }

    if (collectDate == '') {
      this.packSelectError = true;
      this.packSelectErrorMessage = '\n- Preencha a data da coleta.';
    }

    if (vehicleType == '') {
      this.packSelectError = true;
      this.packSelectErrorMessage = '\n- Preencha o tipo do veículo.';
    }

    if (transportType == '') {
      this.packSelectError = true;
      this.packSelectErrorMessage = '\n- Preencha o tipo de transporte.';
    }

    const auxCollectDateTime = new Date(
      collectDate.year,
      collectDate.month - 1,
      collectDate.day,
      collectTime.substring(0, 2),
      collectTime.substring(3, 5)
    );
    let collectDateTime = auxCollectDateTime.toISOString();
    collectDateTime = collectDateTime.substring(0, collectDateTime.length - 1);

    if (!this.intermediaryExpedition.id) {
      await this.sequenceService
        .newExpeditionRequest(
          transportType,
          vehicleType,
          collectDateTime,
          assemblyPackIds
        )
        .toPromise()
        .then(
          res => {
            this.packSelectError = false;
            this.uniquePopidList = res.expeditionLineDtos;
          },
          error => {
            if (error.error.code === 4005) {
              try {
                this.packSelectErrorMessage = '';
                const missingPopids = JSON.parse(error.error.message);
                this.packSelectError = true;
                this.packSelectErrorMessage +=
                  'Os seguintes POPIDs não estão completos: \n\n';
                missingPopids.map(
                  item =>
                    (this.packSelectErrorMessage += item.toString() + '\n')
                );
                this.packSelectErrorMessage +=
                  '\nSelecione as embalagens faltantes para continuar.';
              } catch {
                this.packSelectError = true;
                this.packSelectErrorMessage = error.error.message
                  ? error.error.message
                  : error.message;
              }
            } else if (error.error.code === 4006) {
              try {
                this.packSelectErrorMessage = '';
                const boxesAlreadyDispatched = JSON.parse(error.error.message);
                this.packSelectError = true;
                this.packSelectErrorMessage +=
                  'As seguintes embalagens já estão presentes em outra expedição: \n\n';

                boxesAlreadyDispatched.map(
                  item =>
                    (this.packSelectErrorMessage += `Ref.: ${
                      item.refNumber
                    } - ${item.productType}, Fornecimento: ${
                      item.supplier
                    }, Data de montagem: ${this.transformDateFormatToTable(
                      item.sequenceDate
                    )} \n`)
                );
              } catch {
                this.packSelectError = true;
                this.packSelectErrorMessage = error.error.message
                  ? error.error.message
                  : error.message;
              }
            } else {
              this.packSelectError = true;
              this.packSelectErrorMessage = error.error.message
                ? error.error.message
                : error.message;
            }
          }
        );
    } else {
      if (id != undefined){
        await this.sequenceService
          .editExpeditionRequest(
            id,
            transportType,
            vehicleType,
            collectDateTime,
            assemblyPackIds
          )
          .toPromise()
          .then(
            res => {
              this.packSelectError = false;
              this.uniquePopidList = res.expeditionLineDtos;
            },
            error => {
              if (error.error.code === 4005) {
                try {
                  this.packSelectErrorMessage = '';
                  const missingPopids = JSON.parse(error.error.message);
                  this.packSelectError = true;
                  this.packSelectErrorMessage +=
                    'Os seguintes POPIDs não estão completos: \n\n';
                  missingPopids.map(
                    item =>
                      (this.packSelectErrorMessage += item.toString() + '\n')
                  );
                  this.packSelectErrorMessage +=
                    '\nSelecione as embalagens faltantes para continuar.';
                } catch {
                  this.packSelectError = true;
                  this.packSelectErrorMessage = error.error.message
                    ? error.error.message
                    : error.message;
                }
              } else if (error.error.code === 4006) {
                try {
                  this.packSelectErrorMessage = '';
                  const boxesAlreadyDispatched = JSON.parse(
                    error.error.message
                  );
                  this.packSelectError = true;
                  this.packSelectErrorMessage +=
                    'As seguintes embalagens já estão presentes em outra expedição: \n\n';

                  boxesAlreadyDispatched.map(
                    item =>
                      (this.packSelectErrorMessage += `Ref.: ${
                        item.refNumber
                      } - ${item.productType}, Fornecimento: ${
                        item.supplier
                      }, Data de montagem: ${this.transformDateFormatToTable(
                        item.sequenceDate
                      )} \n`)
                  );
                } catch {
                  this.packSelectError = true;
                  this.packSelectErrorMessage = error.error.message
                    ? error.error.message
                    : error.message;
                }
              } else {
                this.packSelectError = true;
                this.packSelectErrorMessage = error.error.message
                  ? error.error.message
                  : error.message;
              }
            }
          );
      }
    }
    this.printDisabled = this.packSelectError;
    this.buttonLoading = false;
    this.isEditable = !this.buttonLoading;

    if(!this.printDisabled){
      step.completed = true;
      stepper.next();
    }
  }

  /* generateUniquePopidList() {
    const printList: Parts[] = [];
    // Get each selected parts
    this.sequences?.map((sequence) => {
      sequence.Packs?.map((pack) => {
        pack.Parts?.map((part) => {
          if (part.select) printList.push(part);
        })
      })
    })
    this.uniquePopidList = printList.filter((value, index, self) => self.findIndex(item => item.popId === value.popId) === index);
  } */

  // Get all checked items (parts)
  goToPrint(): void {
    const collectDate = this.firstFormGroup.value.collectDate;
    const collectTime = this.firstFormGroup.value.collectTime;
    const CollectDateTime = new Date(
      collectDate.year,
      collectDate.month - 1,
      collectDate.day,
      collectTime.substring(0, 2),
      collectTime.substring(3, 5)
    );
    const DateTime = CollectDateTime.toISOString();
    console.log(DateTime);

    const expeditionForm = {
      formHeader: {
        collectTime: DateTime,
        transportType: this.firstFormGroup.value.transportType,
        vehicleType: this.firstFormGroup.value.vehicleType,
      },
      uniquePopidList: this.uniquePopidList,
    };

    this.router.navigate([
      '/',
      {
        outlets: {
          print: ['print', JSON.stringify(expeditionForm)],
        },
      },
    ]);
    setTimeout(() => {
      window.print();
      this.router.navigate([{ outlets: { print: null } }]);
    }, 1000);
  }
}
