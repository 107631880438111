import {
  Component,
  OnInit,
  OnDestroy,
  ViewChildren,
  QueryList,
} from '@angular/core';
import { ChartConfiguration, ChartData, ChartType } from 'chart.js';
import { BaseChartDirective } from 'ng2-charts';
import { formatDate } from '@angular/common';

import { LogisticService } from '@services/logistic.service';

interface dataSet {
  partNumbers: string[];
  allCount: number[];
  scanedCount: number[];
}

@Component({
  selector: 'app-logistic-panel',
  templateUrl: './logistic-panel.component.html',
  styleUrls: ['./logistic-panel.component.scss'],
})
export class LogisticPanelComponent implements OnInit, OnDestroy {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  @ViewChildren(BaseChartDirective) charts: QueryList<BaseChartDirective> | any;

  inputDate = '';
  interval: NodeJS.Timeout;

  bumper: dataSet = {
    partNumbers: [],
    allCount: [],
    scanedCount: [],
  };

  deflector: dataSet = {
    partNumbers: [],
    allCount: [],
    scanedCount: [],
  };

  mudguard: dataSet = {
    partNumbers: [],
    allCount: [],
    scanedCount: [],
  };

  public barChartOptionsBumper: ChartConfiguration['options'] = {
    maintainAspectRatio: false,
    scales: {
      x: {
        title: {
          text: 'Part Numbers',
          display: true,
        },
      },
      y: {
        type: 'linear',
        min: 0,
        ticks: {
          precision: 0,
        },
        title: {
          text: 'Qntd. Peças',
          display: true,
        },
      },
    },
    plugins: {
      title: {
        display: true,
        text: 'Bumper',
      },
      legend: {
        display: true,
        align: 'end',
      },
    },
  };

  public barChartOptionsDeflector: ChartConfiguration['options'] = {
    maintainAspectRatio: false,
    scales: {
      x: {
        title: {
          text: 'Part Numbers',
          display: true,
        },
      },
      y: {
        type: 'linear',
        min: 0,
        ticks: {
          precision: 0,
        },
        title: {
          text: 'Qntd. Peças',
          display: true,
        },
      },
    },
    plugins: {
      title: {
        display: true,
        text: 'Defletor',
      },
      legend: {
        display: true,
        align: 'end',
      },
    },
  };

  public barChartOptionsMudguard: ChartConfiguration['options'] = {
    maintainAspectRatio: false,
    scales: {
      x: {
        title: {
          text: 'Part Numbers',
          display: true,
        },
      },
      y: {
        type: 'linear',
        min: 0,
        ticks: {
          precision: 0,
        },
        title: {
          text: 'Qntd. Peças',
          display: true,
        },
      },
    },
    plugins: {
      title: {
        display: true,
        text: 'Mudguard',
      },
      legend: {
        display: true,
        align: 'end',
      },
    },
  };

  public barChartType: ChartType = 'bar';

  public barChartDataBumper: ChartData<'bar'> = {
    labels: this.bumper.partNumbers,
    datasets: [
      {
        data: this.bumper.allCount,
        backgroundColor: '#FF6853',
        label: 'Entrada',
      },
      {
        data: this.bumper.scanedCount,
        backgroundColor: '#42ADEA',
        label: 'Saída',
      },
    ],
  };

  public barChartDataDeflector: ChartData<'bar'> = {
    labels: this.deflector.partNumbers,
    datasets: [
      {
        data: this.deflector.allCount,
        backgroundColor: '#FF6853',
        label: 'Entrada',
      },
      {
        data: this.deflector.scanedCount,
        backgroundColor: '#42ADEA',
        label: 'Saída',
      },
    ],
  };

  public barChartDataMudguard: ChartData<'bar'> = {
    labels: this.mudguard.partNumbers,
    datasets: [
      {
        data: this.mudguard.allCount,
        backgroundColor: '#FF6853',
        label: 'Entrada',
      },
      {
        data: this.mudguard.scanedCount,
        backgroundColor: '#42ADEA',
        label: 'Saída',
      },
    ],
  };

  constructor(private logisticService: LogisticService) {
    if (this.inputDate == '') {
      const currentdate = new Date();
      this.inputDate = formatDate(
        currentdate,
        'yyyy-MM-dd',
        navigator.language
      );
    }
    this.updateChartDate(this.inputDate);
    this.interval = setInterval(
      () => this.updateChartDate(this.inputDate),
      60000
    );
  }

  ngOnInit(): void {
    return;
  }

  ngOnDestroy(): void {
    if (this.interval) clearInterval(this.interval);
  }

  async updateChartDate(date: string) {
    await this.logisticService
      .getLogisticData(
        'bumper',
        formatDate(date, 'yyyyMMdd', navigator.language)
      )
      .toPromise()
      .then(data => {
        this.bumper.partNumbers = [];
        this.bumper.allCount = [];
        this.bumper.scanedCount = [];

        data.map(part => {
          this.bumper.partNumbers.push(part.partNumber);
          this.bumper.allCount.push(part.allCount);
          this.bumper.scanedCount.push(part.scanedCount);
        });

        this.barChartDataBumper.labels = this.bumper.partNumbers;
        this.barChartDataBumper.datasets[0].data = this.bumper.allCount;
        this.barChartDataBumper.datasets[1].data = this.bumper.scanedCount;
        this.charts.toArray()[0].update();
      });

    await this.logisticService
      .getLogisticData(
        'deflector',
        formatDate(date, 'yyyyMMdd', navigator.language)
      )
      .toPromise()
      .then(data => {
        this.deflector.partNumbers = [];
        this.deflector.allCount = [];
        this.deflector.scanedCount = [];

        data.map(part => {
          this.deflector.partNumbers.push(part.partNumber);
          this.deflector.allCount.push(part.allCount);
          this.deflector.scanedCount.push(part.scanedCount);
        });

        this.barChartDataDeflector.labels = this.deflector.partNumbers;
        this.barChartDataDeflector.datasets[0].data = this.deflector.allCount;
        this.barChartDataDeflector.datasets[1].data =
          this.deflector.scanedCount;
        this.charts.toArray()[1].update();
      });

    await this.logisticService
      .getLogisticData(
        'mudguard',
        formatDate(date, 'yyyyMMdd', navigator.language)
      )
      .toPromise()
      .then(data => {
        this.mudguard.partNumbers = [];
        this.mudguard.allCount = [];
        this.mudguard.scanedCount = [];

        data.map(part => {
          this.mudguard.partNumbers.push(part.partNumber);
          this.mudguard.allCount.push(part.allCount);
          this.mudguard.scanedCount.push(part.scanedCount);
        });

        this.barChartDataMudguard.labels = this.mudguard.partNumbers;
        this.barChartDataMudguard.datasets[0].data = this.mudguard.allCount;
        this.barChartDataMudguard.datasets[1].data = this.mudguard.scanedCount;
        this.charts.toArray()[2].update();
      });
  }
}
