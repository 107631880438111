/*
 * File: logs.service.ts
 * Project: boxpart-console
 * File Created: Tuesday, 14th December 2021 18:17:30 pm
 * Author: Bruno Lopes (bruno.lorenco.lopes@gmail.com)
 * -----
 * Copyright 2021 - Von Braun Labs.
 * -----
 * HISTORY:
 * Date      	By	Comments
 * ----------	---	---------------------------------------------------------
 * 2021-12-14	BL	Get log entries.
 */

import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from '@env';
import { Observable } from 'rxjs';
import { IprintLogEntry, LogEntry } from '../common/types/logs.types';
import { LoginService } from './login.service';

/** API URL defined in environment files. */
const API = environment.applicationApiUrl;

/** Provides application authentication services. */
@Injectable()
export class LogsService {
  constructor(private http: HttpClient, private login: LoginService) {}

  private prepareRequest() {
    const auth = this.login.loadAuthData();
    const headers = new HttpHeaders().set(
      'Authorization',
      `bearer ${auth.token}`
    );
    return headers;
  }

  /**
   * Retrieves log entries from backend
   * @returns an LogsDataTablesResponse observable.
   */
  public retrieveLogEntries(): Observable<LogEntry[]> {
    const headers = this.prepareRequest();
    return this.http.get<LogEntry[]>(API + '/logger/logs', { headers });
  }

  /**
   * Request print log creation
   */
   public logPrint(result:string, dateTime: string, data: string): Observable<IprintLogEntry>{

    const body = {
      result: result,
      timestamp: dateTime,
      data: data,
    };

    const headers = this.prepareRequest();
    return this.http.post<IprintLogEntry>(API + '/logger/print', body ,{ headers });
  }
}
