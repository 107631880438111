import { tagList } from './../../common/types/tags.types';
import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';

@Component({
  selector: 'app-generate-tags',
  templateUrl: './generate-tags.component.html',
  styleUrls: ['./generate-tags.component.css'],
})
export class GenerateTagsComponent implements OnInit {
  tags: tagList[] = [];

  constructor(private route: ActivatedRoute) {}

  ngOnInit(): void {
    this.route.queryParams.subscribe(
      params => (this.tags = JSON.parse(params.tags))
    );
  }
}
